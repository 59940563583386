import { useEffect, useState } from "react"
import { Box } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import { Container } from "@mui/system"

import NavBar from "../../components/Layout/NavBar"
import { SubscriptionCard } from "../../components/SubscriptionCard"
import { useAxiosPrivate } from "../../hooks/useAxiosPrivate"

export const RsgSubStatus = {
  active: "active",
  unsubscribed: "unsubscribed",
  payment_failed: "payment_failed",
  inactive: "inactive",

  // Stripe Statuses which are not mapped to RSG statuses
  incomplete: "incomplete",
  incomplete_expired: "incomplete_expired",
  trialing: "trialing",
  unpaid: "unpaid",
} as const
export type RsgSubStatus = (typeof RsgSubStatus)[keyof typeof RsgSubStatus]

export interface TransferDetails {
  transfereeEmail: string
  createdTime: number
  transferredTime: number | null
  status: "pending" | "success"
}
export interface Subscription {
  id: string
  startUnixS: number
  endUnixS: number
  interval: string
  intervalCount: number
  product: {
    name: string
    description: string
    caption?: string
  }
  price: number
  currency: string
  status: RsgSubStatus
  cancelAt: number // unix seconds
  isTransferrable: boolean
  transferDetails: TransferDetails
  hasPrevTransferPassMinDuration: boolean | null
}

export default function CustomerSubscription() {
  const axiosPrivate = useAxiosPrivate()
  const [subs, setSubs] = useState<Subscription[]>([])
  const [isLoadingSubs, setIsLoadingSubs] = useState(false)

  const handleUnsubscribe = async (subId: string) => {
    const url = "/users/unsubscribe"

    try {
      await axiosPrivate.post(url, { subId })
      await getUserSubscriptions()
    } catch (err: any) {
      // console.log(err.message)
    }
  }

  const handleResubscribe = async (subId: string) => {
    const url = "/users/resubscribe"

    try {
      await axiosPrivate.post(url, { subId })
      await getUserSubscriptions()
    } catch (err: unknown) {
      // console.log(err.message)
    }
  }

  const getUserSubscriptions = async () => {
    setIsLoadingSubs(true)
    // require auth
    try {
      const url = "/users/subscriptions"
      const { data } = await axiosPrivate.get<{
        subscriptions: Subscription[]
      }>(url)

      if (Array.isArray(data?.subscriptions)) {
        setSubs(data.subscriptions)
      }
    } catch (err: any) {
      console.error(err.message)
    } finally {
      setIsLoadingSubs(false)
    }
  }

  useEffect(() => {
    getUserSubscriptions()
  }, [])

  return (
    <Box mb={10}>
      <NavBar title="Manage Subscription" navigateTo="/profile" />
      <List>
        <ListItem divider={true}>
          <ListItemButton>
            <ListItemText primary="Subscriptions" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <Container>
            {isLoadingSubs && (
              <Box py={5} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}
            {!isLoadingSubs && subs.length > 0 && (
              <Box display="flex" flexDirection="column" gap={3}>
                {subs.map((sub, i) => {
                  return (
                    <SubscriptionCard
                      key={i}
                      sub={sub}
                      handleUnsubscribe={handleUnsubscribe}
                      handleResubscribe={handleResubscribe}
                    />
                  )
                })}
              </Box>
            )}
            {!isLoadingSubs && subs.length === 0 && (
              <Box display="flex" justifyContent="center" py={5}>
                No Subscriptions Found.
              </Box>
            )}
          </Container>
        </ListItem>
      </List>
    </Box>
  )
}
