import { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { Box, Button, Container, Typography } from "@mui/material"
import moment from "moment"
import Papa from "papaparse"

import { MainNav } from "../components/Layout/MainNav"
import { UserTable } from "../components/UserTable"
import { useAxiosPrivate } from "../hooks/useAxiosPrivate"

export interface IUser {
  name: string
  email: string
  mobileNo: string
  roles: string[]
  subscriptions: {
    subStartS: number
    subEndS: number
    quizCategories: ("Paper 1" | "Paper 2")[]
  }[]
  createdAt: string
}

export const User = () => {
  const axiosPrivate = useAxiosPrivate()
  const [users, setUsers] = useState<IUser[]>([])
  const [startDate, setStartDate] = useState<Date | null>(
    moment().subtract(1, "months").toDate()
  )
  const [endDate, setEndDate] = useState<Date | null>(new Date())

  const handleDownloadCSV = async () => {
    const startUnixMs = moment(startDate).valueOf()
    const endUnixMs = moment(endDate).valueOf()
    const { data } = await axiosPrivate.get<{
      users: IUser[]
    }>(`/users?startUnixMs=${startUnixMs}&endUnixMs=${endUnixMs}`)
    const parsedUserResponses = parseUserResponses(data.users)
    const csv = Papa.unparse(parsedUserResponses)
    const blob = new Blob([csv], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")
    const startDateFormatted = moment(startDate).format("YYMMDD")
    const endDateFormatted = moment(endDate).format("YYMMDD")
    link.download = `${startDateFormatted}-${endDateFormatted}-users.csv`
    link.href = url
    link.click()
  }

  const parseUserResponses = (users: IUser[]) => {
    return users.map(({ createdAt, email, name, mobileNo }) => {
      return {
        dateSignedUp: moment(createdAt).format("YYYY-MM-DD HH:mm"),
        email,
        name,
        mobileNo,
      }
    })
  }

  useEffect(() => {
    const getUsers = async () => {
      // require auth
      try {
        const url = "/users"
        const { data } = await axiosPrivate.get(url)

        if (Array.isArray(data?.users)) {
          setUsers(data.users)
        }
      } catch (err: any) {
        // console.error(err.message)
      }
    }

    getUsers()
  }, [])

  return (
    <Box>
      <MainNav />
      <Container sx={{ mt: 5 }}>
        <Typography variant="h4" sx={{ color: "rgb(42, 124, 111)", mb: 2 }}>
          Users ({users.length})
        </Typography>
        <Box display="flex" sx={{ my: 5 }} alignItems="center" columnGap={3}>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="yyyy-MM-dd"
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="yyyy-MM-dd"
          />
          <Button
            variant="contained"
            size="small"
            onClick={async () => await handleDownloadCSV()}
          >
            Export CSV
          </Button>
        </Box>
        <UserTable users={users} />
      </Container>
    </Box>
  )
}
