import { useNavigate } from "react-router-dom"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import PersonIcon from "@mui/icons-material/Person"
import { AppBar, Box, Button, Container, Typography } from "@mui/material"

import { PRIMARY_FONT, RESEXAMSG_FAQ, ROLES } from "../../constants"
import { useAuth } from "../../hooks/useAuth"

export const MainNav = ({
  subTerminatingDate = null,
  handleResubscribe = null,
}: {
  subTerminatingDate?: string | null
  handleResubscribe?: any
}) => {
  const navigate = useNavigate()
  const { auth } = useAuth()

  return (
    <>
      <Box id="hero">
        {/* <Box height="50px" /> */}
        <Container
          sx={{
            display: "flex",
            p: 2,
            justifyContent: "space-between",
            height: "72px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              textDecoration: "none",
              color: "#FFFFFF",
              border: "2px solid white",
              padding: "8px 12px",
              borderRadius: 8,
            }}
            href="https://resexam.sg"
            target="_blank"
            rel="noreferrer"
          >
            {/* <img
            src="resexam_logo.svg"
            alt="resexam_logo.svg"
            width="150"
            height="150"
            style={{ maxWidth: '100%', height: 'auto', padding: 0, margin: 0 }}
          /> */}
            <Typography
              sx={{
                fontSize: "1.5rem",
                fontWeight: 800,
                // '@media (min-width: 780px)': {
                //   fontSize: '1.5rem'
                // }
              }}
            >
              RESexam.sg
            </Typography>
          </a>
          {/* <Typography
            component="a"
            href="https://www.resexam.sg/"
            rel="noreferrer"
            style={{
              textDecoration: 'none',
              fontSize: '20px',
              fontFamily: 'Arial,Helvetica,sans-serif',
              color: GREEN_COLOR
            }}
          >
            RESEXAM.SG
          </Typography> */}
          {auth?.accessToken && auth?.roles?.includes(ROLES.Admin) && (
            <Box
              display="flex"
              flexGrow={2}
              justifyContent="space-evenly"
              sx={{ color: "#ffffff", fontSize: "1rem" }}
            >
              <Typography
                onClick={() => {
                  navigate("/upload")
                }}
                sx={{
                  "&:hover": { cursor: "pointer" },
                  fontWeight: 600,
                }}
              >
                Upload
              </Typography>
              <Typography
                onClick={() => {
                  navigate("/quiz-feedback")
                }}
                sx={{
                  "&:hover": { cursor: "pointer" },
                  fontWeight: 600,
                }}
              >
                Quiz Feedback
              </Typography>
              <Typography
                onClick={() => {
                  navigate("/users")
                }}
                sx={{
                  "&:hover": { cursor: "pointer" },
                  fontWeight: 600,
                }}
              >
                Users
              </Typography>
              <Typography
                onClick={() => {
                  navigate("/survey-responses")
                }}
                sx={{
                  "&:hover": { cursor: "pointer" },
                  fontWeight: 600,
                }}
              >
                Survey Responses
              </Typography>
            </Box>
          )}
          <Box display="flex" gap={2} alignItems="center">
            {!auth.accessToken && (
              <Button
                variant="contained"
                sx={{
                  borderRadius: 8,
                  transition: "opacity 0.3s, transform 0.3s",
                }}
                onClick={() => {
                  navigate("/login")
                  navigate(0)
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.25rem",
                    textTransform: "capitalize",
                  }}
                >
                  Login
                </Typography>
              </Button>
            )}
            {auth.accessToken && (
              <Box>
                <PersonIcon
                  onClick={() => {
                    navigate("/profile")
                  }}
                  sx={{
                    fontSize: "2rem",
                    "&:hover": { cursor: "pointer" },
                    color: "white",
                  }}
                />
              </Box>
            )}
            <a
              href={RESEXAMSG_FAQ}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <HelpOutlineIcon
                sx={{
                  cursor: "pointer",
                  color: "#ffffff",
                  fontSize: "2rem",
                  opacity: 0.8,
                }}
              />
            </a>
          </Box>
        </Container>
        <Container>
          <div id="hero-text">
            <Typography
              sx={{
                fontSize: "2.25rem",
                lineHeight: 1,
                fontStyle: "italic",
                fontWeight: 600,
                "@media (min-width: 780px)": {
                  fontSize: "3.5rem",
                },
              }}
            >
              Welcome to <br /> RES Exam SG
            </Typography>
            <Typography
              sx={{
                fontSize: "1rem",
                lineHeight: 1,
                fontStyle: "italic",
                "@media (min-width: 780px)": {
                  fontSize: "1.5rem",
                },
              }}
            >
              Simple, fuss-free online learning
            </Typography>
          </div>
        </Container>
        {subTerminatingDate && (
          <AppBar position="sticky" sx={{ backgroundColor: "#bde9e2" }}>
            <Container sx={{ my: 1 }}>
              <Box
                display="flex"
                bgcolor="white"
                color="black"
                p={3}
                justifyContent="center"
                borderRadius={5}
                flexDirection="column"
                rowGap={1}
              >
                <Typography fontWeight="bold">Plan Expiring</Typography>
                <Typography>
                  You will lose access to RES Exam questions on{" "}
                  {subTerminatingDate}. Resume subscription to keep practicing
                  and pass the exam.
                </Typography>
                <Typography
                  fontWeight="bold"
                  color="blue"
                  sx={{ cursor: "pointer" }}
                  onClick={handleResubscribe}
                >
                  Resume subscription
                </Typography>
              </Box>
            </Container>
          </AppBar>
        )}
      </Box>
    </>
  )
}
