import { Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'

export default function QuizOption2({
  isRed,
  isGreen,
  handleSubmitMcq,
  choiceIndex,
  isAnswerSubmitted,
  isSubmittingAnswer,
  choice
}: {
  isRed: boolean
  isGreen: boolean
  handleSubmitMcq: any
  choiceIndex: number
  isAnswerSubmitted: boolean
  isSubmittingAnswer: boolean
  choice: string
}) {
  const isOptionDisabled = isAnswerSubmitted || isSubmittingAnswer

  return (
    <Grid2
      xs={12}
      className={`option ${isGreen && 'correct-choice'} ${
        isRed && 'incorrect-choice'
      }`}
      sx={{
        cursor: `${isOptionDisabled ? 'not-allowed' : 'pointer'}`,
        padding: '16px'
      }}
      role="button"
      onClick={() => {
        if (!isOptionDisabled) {
          handleSubmitMcq(choiceIndex)
        }
      }}
    >
      <Typography fontSize="1rem">{choice}</Typography>
    </Grid2>
  )
}
