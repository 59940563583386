import {
  Backdrop,
  Box,
  Button,
  Container,
  Paper,
  Typography,
} from "@mui/material"

interface Props {
  isShowRatingBackdrop: boolean
  setIsShowRatingBackdrop: any
  rating: number
  setRating: any
  handleSubmitRating: any
}
const RatingBackdrop = ({
  isShowRatingBackdrop,
  setIsShowRatingBackdrop,
  rating,
  setRating,
  handleSubmitRating,
}: Props) => {
  return (
    <Box>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          overflowY: "scroll",
        }}
        open={isShowRatingBackdrop}
      >
        <Paper
          sx={{
            maxWidth: "25rem",
            minWidth: "2rem",
            backgroundColor: "white",
            borderRadius: "20px",
            mt: 5,
          }}
        >
          <Container
            sx={{
              py: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              rowGap: 2,
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              How would you rate your overall experience with RES Exam SG?
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1rem",
              }}
            >
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={star}
                  onClick={() => setRating(star)}
                  onKeyDown={() => setRating(star)}
                  tabIndex={0}
                  role="button"
                  style={{
                    cursor: "pointer",
                    color: star <= rating ? "gold" : "gray",
                    fontSize: "36px",
                  }}
                >
                  &#9733;
                </span>
              ))}
            </Box>
            <Button
              variant="contained"
              role="button"
              sx={{
                px: 5,
                borderRadius: 5,
                width: "15rem",
              }}
              onClick={() => handleSubmitRating(rating)}
            >
              Submit
            </Button>
          </Container>
        </Paper>
      </Backdrop>
    </Box>
  )
}
export default RatingBackdrop
