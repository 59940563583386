import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import { DataGrid, GridColDef } from '@mui/x-data-grid'

export const QuizTable = ({
  quizzes,
  deleteQuiz,
  updateQuiz
}: {
  quizzes: any[]
  deleteQuiz: any
  updateQuiz: any
}) => {
  const columns: GridColDef[] = [
    {
      field: 'topic',
      headerName: 'Topic',
      width: 250
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 250
    },
    {
      field: 'noOfQuestions',
      headerName: 'No. of Questions',
      type: 'number',
      width: 250
    },
    {
      field: 'order',
      headerName: 'Order',
      type: 'singleSelect',
      width: 250,
      valueOptions: ({ id, row }) => {
        const noOfpaper1Quizzes = quizzes.filter(
          ({ category }) => category === 'Paper 1'
        ).length
        const noOfpaper2Quizzes = quizzes.filter(
          ({ category }) => category === 'Paper 2'
        ).length
        const noOffreeQuizzes = quizzes.filter(
          ({ category }) => category === 'Free'
        ).length

        if (row.category === 'Paper 1') {
          return [...Array(noOfpaper1Quizzes).keys()].map((i) => i)
        } else if (row.category === 'Paper 2') {
          return [...Array(noOfpaper2Quizzes).keys()].map((i) => i)
        } else if (row.category === 'Free') {
          return [...Array(noOffreeQuizzes).keys()].map((i) => i)
        }

        return []
      },
      editable: true,
      valueFormatter: (params) => {
        return params.value + 1
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 250,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => deleteQuiz(e, params.row)}
            variant="contained"
          >
            Delete
          </Button>
        )
      }
    }
  ]

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        getRowId={(row) => row._id}
        rows={quizzes}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 30]}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={async (row) => {
          const { _id, order } = row
          // Update quiz order
          await updateQuiz(_id, order)
          return row
        }}
        onProcessRowUpdateError={(error) => console.log(error)}
        disableSelectionOnClick
      />
    </Box>
  )
}
