import { useEffect, useRef, useState } from 'react'
import { Box, Button, Paper } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { AxiosError, default as OriginalAxios } from 'axios'
import moment from 'moment'

import { Api } from '../Api'
import { validateEmail } from '../helpers/inputValidators'

export const EmailBackdrop = () => {
  const [isEmailBackdropOpen, setIsEmailBackdropOpen] = useState(true)
  const emailRef = useRef<HTMLInputElement>(null)
  /**
   * EMAIL
   */
  const [email, setEmail] = useState('')
  const [isSubmittingEmail, setIsSubmittingEmail] = useState(false)
  const [invalidEmailText, setInvalidEmailText] = useState<string | null>(null)

  useEffect(() => {
    emailRef.current?.focus()
  }, [])

  const handleSubmitEmail = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setIsSubmittingEmail(true)
    setInvalidEmailText('')

    // validate email
    if (!validateEmail(email)) {
      setInvalidEmailText('Please enter a valid email.')
      setIsSubmittingEmail(false)
      return
    }

    try {
      // insert lead
      await Api.insertLead(email)

      // --- insert success ---

      // set email in session storage
      sessionStorage.setItem('email', email)

      // close backdrop
      setIsEmailBackdropOpen(false)
    } catch (err: unknown) {
      const error = err as Error | AxiosError
      if (
        OriginalAxios.isAxiosError(error) &&
        error?.response?.data?.message === 11000
      ) {
        // update lead instead
        try {
          const currentUnixS = moment().unix()
          await Api.updateLead(email, { currentUnixS })

          // --- update success ---

          // set email in session storage
          sessionStorage.setItem('email', email)

          // close backdrop
          setIsEmailBackdropOpen(false)
        } catch (err) {
          setInvalidEmailText('Something went wrong. Please try again later.')
        }
      } else if (
        OriginalAxios.isAxiosError(error) &&
        error?.response?.data?.errorCode === 'INVALID_EMAIL'
      ) {
        setInvalidEmailText('Please enter a valid email')
      } else {
        setInvalidEmailText('Something went wrong. Please try again later.')
      }
    } finally {
      setIsSubmittingEmail(false)
    }
  }

  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 2,
        overflowY: 'scroll'
      }}
      open={isEmailBackdropOpen}
    >
      <Paper
        sx={{
          width: '100%',
          backgroundColor: '#bde9e2'
        }}
      >
        <Container
          sx={{
            p: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              Continue?
            </Typography>
          </Box>
          <Typography variant="subtitle1">
            Enter your email to continue practicing.
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmitEmail}
            display="flex"
            flexDirection="column"
          >
            <TextField
              type="email"
              helperText={invalidEmailText}
              error={invalidEmailText ? true : false}
              placeholder="johndoe@gmail.com"
              variant="outlined"
              label="EMAIL"
              value={email}
              sx={{ backgroundColor: 'white', mt: 3, width: '250px' }}
              onChange={(e) => setEmail(e.target.value)}
              inputRef={emailRef}
              autoComplete="false"
            />
            <Button
              variant="contained"
              sx={{ backgroundColor: '#2a7c6f', px: 5, borderRadius: 5, mt: 3 }}
              disabled={isSubmittingEmail}
              type="submit"
            >
              Let&#39;s Go
            </Button>
          </Box>
        </Container>
      </Paper>
    </Backdrop>
  )
}
