import React, { useEffect, useRef, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { SelectChangeEvent } from '@mui/material/Select'
import Papa from 'papaparse'

import { IQuestion, QuestionType, QuizCategory } from '../Api'
import { MainNav } from '../components/Layout/MainNav'
import { QuizTable } from '../components/QuizTable'
import { ERROR_CODE } from '../constants'
import axios from '../helpers/axios'
import { useAxiosPrivate } from '../hooks/useAxiosPrivate'
export interface IQuestionCSV {
  Question: string
  A: string
  B: string
  C: string
  D: string
  Answer: string
  Feedback: string
}

const requiredCols = [
  'SN',
  'Question',
  'A',
  'B',
  'C',
  'D',
  'Answer',
  'Feedback'
]

export const parseQuestions = (
  data: IQuestionCSV[]
): [string | null, IQuestion[]] => {
  const answerMapping: { [key: string]: number } = {
    A: 0,
    B: 1,
    C: 2,
    D: 3
  }
  const parsedQuestions: IQuestion[] = []
  let error = ''

  data.every((row, i) => {
    const rowNo = i + 1 // add 1 because index 0
    const { Question, A, B, C, D, Answer, Feedback } = row

    // check if Answer is available
    if (!Answer || !Answer.trim()) {
      error = `Answer is missing for row ${rowNo}`
      return false
    }

    // determine if type of question is MCQ based on whether option A is available
    const isMcq = A.trim() !== ''

    // check if B, C, D is available if isMcq is true
    if (isMcq) {
      if (B.trim() === '') {
        error = `Answer B is missing for row ${rowNo}`
        return false
      } else if (C.trim() === '') {
        error = `Answer C is missing for row ${rowNo}`
        return false
      } else if (D.trim() === '') {
        error = `Answer D is missing for row ${rowNo}`
        return false
      }

      // ensure if 'Answer' field is either 'A', 'B', 'C' or 'D'
      if (!Object.keys(answerMapping).includes(Answer)) {
        error = `Row ${rowNo}: 'Answer' field should be either 'A', 'B', 'C' or 'D' for MCQ question`
        return false
      }
    }

    const finalObj = {
      question: Question,
      type: isMcq ? QuestionType.MCQ : QuestionType.Fill,
      ...(isMcq && { choices: [A.trim(), B.trim(), C.trim(), D.trim()] }),
      ...(isMcq && { correctChoiceIndex: answerMapping[Answer] }),
      ...(!isMcq && { possibleAnswers: Answer.split(',') }),
      explanation: Feedback
    }

    parsedQuestions.push(finalObj)
    return true
  })

  if (error) {
    return [error, []]
  }

  return [null, parsedQuestions]
}

export const Upload = () => {
  const [category, setCategory] = useState<QuizCategory>(QuizCategory.PAPER_1)
  const [imageFile, setImageFile] = useState<File | null>(null)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [successMsg, setSuccessMsg] = useState<string>('')
  const [questions, setQuestions] = useState<IQuestion[]>([])
  const [isUploading, setIsUploading] = useState(false)
  const topicRef = useRef<HTMLInputElement>(null)
  const axiosPrivate = useAxiosPrivate()
  const [quizzes, setQuizzes] = useState([])

  useEffect(() => {
    // get quizzes
    const getQuizzes = async () => {
      try {
        const {
          data: { quizzes }
        } = await axios('/quizzes')
        setQuizzes(quizzes)
      } catch (err) {
        console.error(err)
      }
    }

    getQuizzes()
  }, [])

  const deleteQuiz = async (e: any, row: any) => {
    e.stopPropagation()
    const isConfirm = window.confirm('Are you sure?')
    if (isConfirm) {
      try {
        await axiosPrivate.delete(`/quizzes/${row._id}`)
        // remove from quizzes
        const {
          data: { quizzes }
        } = await axios('/quizzes')
        setQuizzes(quizzes)
      } catch (err) {
        console.error(err)
      }
    }
  }

  const updateQuiz = async (quizId: string, order: number) => {
    try {
      await axiosPrivate.put(`/quizzes/${quizId}`, {
        order,
        category
      })

      const {
        data: { quizzes }
      } = await axios('/quizzes')
      setQuizzes(quizzes)
    } catch (err) {
      console.error(err)
    }
  }

  const handleChangeCategory = (event: SelectChangeEvent) => {
    setCategory(event.target.value as any)
  }

  const handleChangeFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // reset success and error messages
    setSuccessMsg('')
    setErrorMsg('')

    if (e.target.files) {
      Papa.parse(e.target.files[0] as any, {
        header: true,
        skipEmptyLines: true,
        complete: function (result: {
          data: IQuestionCSV[]
          meta: {
            aborted: boolean
            delimiter: string
            fields?: string[] | undefined
          }
        }) {
          // validate fields
          if (result.meta.fields) {
            const areFieldsValid = requiredCols.every((field) => {
              if (!result.meta.fields?.includes(field)) {
                setErrorMsg(`Missing field: ${field}`)
                return false
              }
              return true
            })

            if (!areFieldsValid) {
              return
            }
          } else {
            setErrorMsg('No fields detected.')
          }

          // parse data
          const [error, parsedQuestions] = parseQuestions(result.data)

          if (error) {
            setErrorMsg(error)
            return
          }

          // set questions
          setQuestions(parsedQuestions)
        }
      })
    }
  }

  const handleChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files && e.target?.files[0]) {
      setImageFile(e.target?.files[0])
    } else {
      setImageFile(null)
    }
  }

  const handleUpload = async () => {
    setSuccessMsg('')
    setErrorMsg('')
    setIsUploading(true)
    const topic = topicRef.current?.value

    // validate topic
    if (!topic || !topic.trim()) {
      setErrorMsg('Please enter a topic name.')
      return
    }

    // validate image
    if (imageFile === null) {
      setErrorMsg('Please upload an image.')
      return
    }
    // validate questions
    if (questions.length === 0) {
      setErrorMsg('Please upload some questions.')
      return
    }

    try {
      // upload image
      const formData = new FormData()
      formData.append('file', imageFile, imageFile.name)

      const imagesUrl = '/images/upload'
      const { data } = await axiosPrivate.post(imagesUrl, formData, {
        baseURL: '/api/v1',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true
      })

      if (!data || !data.imageLink) {
        setErrorMsg('Something went wrong. Please try again later.')
        return
      }

      const finalObj = {
        topic: topic.trim(),
        category,
        questions,
        imageLink: data.imageLink
      }

      // upload quizzes
      const quizzesUrl = `/quizzes`
      await axiosPrivate.post(quizzesUrl, { quizzes: [finalObj] })

      // Get quizzies again
      const {
        data: { quizzes }
      } = await axios('/quizzes')
      setQuizzes(quizzes)

      // set success message
      setSuccessMsg(
        `Successfully uploaded ${questions.length} questions with topic name ${topicRef.current.value}`
      )

      // reset topic name
      topicRef.current.value = ''

      // reset questions
      setQuestions([])
    } catch (err: any) {
      const errorCode = err.response?.data?.errorCode
      const errorMessage = err.response?.data?.errorMessage

      if (errorCode === ERROR_CODE.DUPLICATE_QUIZ) {
        setErrorMsg(errorMessage)
      } else {
        setErrorMsg('Something went wrong. Please try again later.')
      }
    } finally {
      setIsUploading(false)
    }
  }

  return (
    <Box>
      <MainNav />
      <Grid container sx={{ justifyContent: 'center', my: 5 }}>
        <Grid item xs={12} sm={5} sx={{ mx: 5 }}>
          <Paper sx={{ p: 5 }} elevation={3}>
            <Typography
              variant="h5"
              component="h1"
              sx={{ textAlign: 'center', mb: 3 }}
            >
              Upload Quiz
            </Typography>
            {successMsg && (
              <Alert severity="success" sx={{ my: 2 }}>
                {successMsg}
              </Alert>
            )}
            {errorMsg && (
              <Alert severity="error" sx={{ my: 2 }}>
                {errorMsg}
              </Alert>
            )}
            <Box
              component="form"
              sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}
            >
              <TextField
                label="Topic"
                variant="standard"
                inputRef={topicRef}
                fullWidth
              />
              <FormControl fullWidth>
                <InputLabel id="category">Category</InputLabel>
                <Select
                  labelId="category"
                  id="category"
                  value={category}
                  label="Category"
                  onChange={handleChangeCategory}
                >
                  <MenuItem value={QuizCategory.PAPER_1}>
                    {QuizCategory.PAPER_1}
                  </MenuItem>
                  <MenuItem value={QuizCategory.PAPER_2}>
                    {QuizCategory.PAPER_2}
                  </MenuItem>
                  <MenuItem value={QuizCategory.FREE}>
                    {QuizCategory.FREE}
                  </MenuItem>
                  <MenuItem value={QuizCategory.FREE2}>Free 2</MenuItem>
                </Select>
              </FormControl>
              <Box>
                <input
                  id="btn-upload-img"
                  type="file"
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={handleChangeImage}
                  multiple
                />
                <label htmlFor="btn-upload-img">
                  <Button variant="outlined" color="primary" component="span">
                    Choose Image
                  </Button>
                </label>
              </Box>
              <Box>
                <input
                  id="btn-file"
                  type="file"
                  accept=".csv"
                  style={{ display: 'none' }}
                  onChange={handleChangeFiles}
                />
                <label htmlFor="btn-file">
                  <Button variant="outlined" color="primary" component="span">
                    Choose CSV File
                  </Button>
                </label>
              </Box>
              <Typography component="span">
                No. of Rows: {questions.length}
              </Typography>

              <Box>
                <Button onClick={handleUpload} variant="contained" fullWidth>
                  Upload
                  {isUploading && (
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress sx={{ ml: 1 }} size="1rem" />
                    </Box>
                  )}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={10} sx={{ mx: 5, my: 5 }}>
          <QuizTable
            quizzes={quizzes}
            deleteQuiz={deleteQuiz}
            updateQuiz={updateQuiz}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
