import { useEffect } from 'react'

import { axiosPrivate } from '../helpers/axios'

import { useAuth } from './useAuth'
import { useRefreshToken } from './useRefreshToken'

export const useAxiosPrivate = () => {
  const refresh = useRefreshToken()
  const { auth, setAuth } = useAuth()

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config: any) => {
        if (!config?.headers?.Authorization) {
          config.headers.Authorization = `Bearer ${auth?.accessToken}`
        }

        return config
      },
      (error) => Promise.reject(error)
    )

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true

          try {
            const newAccessToken = await refresh()
            prevRequest.headers = {
              ...prevRequest.headers,
              Authorization: `Bearer ${newAccessToken}`
            }
            return axiosPrivate(prevRequest)
          } catch (err) {
            // refresh endpoint failed
            setAuth({})
            return Promise.reject(err)
          }
        }
        // setAuth({})
        return Promise.reject(error)
      }
    )

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept),
        axiosPrivate.interceptors.response.eject(responseIntercept)
    }
  }, [auth, refresh])

  return axiosPrivate
}
