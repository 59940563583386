import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import { useAuth } from '../hooks/useAuth'
import { useRefreshToken } from '../hooks/useRefreshToken'

export const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true)
  const refresh = useRefreshToken()
  const { auth } = useAuth()

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh()
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    }

    if (!auth.accessToken) {
      verifyRefreshToken()
    } else {
      setIsLoading(false)
    }
  }, [])

  return <>{isLoading ? <div>Loading...</div> : <Outlet />}</>
}
