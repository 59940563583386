import { useNavigate } from 'react-router-dom'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import { Box, Typography } from '@mui/material'

export default function NavBar({
  title,
  navigateTo
}: {
  title: string
  navigateTo: string
}) {
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        backgroundColor: '#151d33',
        color: 'white',
        py: 3,
        px: 2,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Box display="flex" alignItems="center">
        <NavigateBeforeIcon
          sx={{ mr: 1, cursor: 'pointer' }}
          onClick={() => navigate(navigateTo)}
        />
        <Typography variant="h5" component="span">
          {title}
        </Typography>
      </Box>
    </Box>
  )
}
