import { Backdrop, Box, Button, Paper, Typography } from "@mui/material"
import { Container } from "@mui/system"

export interface TransferDetails {
  transfereeEmail: string
  createdTime: number
  transferredTime: number | null
  status: "pending" | "success"
}
export default function ExistingTransferBackdrop({
  isShowExistingTransferBackdrop,
  setIsShowExistingTransferBackdrop,
  transferDetails,
  hasPrevTransferPassMinDuration,
  setIsShowTransferBackdrop,
}: {
  isShowExistingTransferBackdrop: boolean
  setIsShowExistingTransferBackdrop: any
  transferDetails: TransferDetails | null
  hasPrevTransferPassMinDuration: boolean | null
  setIsShowTransferBackdrop: any
}) {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        overflowY: "scroll",
      }}
      open={isShowExistingTransferBackdrop}
    >
      <Paper
        sx={{
          maxWidth: "50rem",
          minWidth: "2rem",
          backgroundColor: "white",
          borderRadius: "20px",
          mt: 5,
        }}
      >
        <Container
          sx={{
            py: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            rowGap: 2,
          }}
        >
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            You&lsquo;ve already initiated a transfer of your subscription to
            <strong>
              <Typography
                display="block"
                component="span"
                sx={{ color: "blue", fontWeight: "bold" }}
              >
                {transferDetails?.transfereeEmail}
              </Typography>
            </strong>{" "}
            Remind the new owner to accept the invite, or wait 24 hours before
            you can transfer to another party.
          </Typography>
          {hasPrevTransferPassMinDuration === false && (
            <Button
              variant="contained"
              color="inherit"
              sx={{ margin: "auto", maxWidth: 200, color: "white" }}
              onClick={() => setIsShowExistingTransferBackdrop(false)}
            >
              Ok
            </Button>
          )}
          {hasPrevTransferPassMinDuration === true && (
            <Box display="flex" justifyContent="space-around">
              <Button
                variant="contained"
                color="inherit"
                onClick={() => setIsShowExistingTransferBackdrop(false)}
              >
                No, I&lsquo;ll wait
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  setIsShowTransferBackdrop(true)
                  setIsShowExistingTransferBackdrop(false)
                }}
              >
                Yes, overwrite
              </Button>
            </Box>
          )}
          <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
            *Remind them to check their inbox / spam folder to accept your
            invite!
          </Typography>
        </Container>
      </Paper>
    </Backdrop>
  )
}
