import { useState } from 'react'
import { Backdrop, Box, Button, CircularProgress, Container, Paper, Typography } from '@mui/material'

import { ERROR_CODE, TRANSFER_ERROR_CODE } from '../constants'
import { useAxiosPrivate } from '../hooks/useAxiosPrivate'

export default function TransferCfmBackdrop({ isShowBackdrop, setIsShowBackdrop, setIsShowTransferBackdrop, setEmail, email, subId }: { isShowBackdrop: boolean; setIsShowBackdrop: any; setIsShowTransferBackdrop: any; setEmail: any; email: string; subId: string }) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const axiosPrivate = useAxiosPrivate()

  const handleSubmitEmail = async (subId: string) => {
    setIsLoading(true)
    setIsSuccess(false)
    setErrorMessage('')

    // Call backend with email
    try {
      await axiosPrivate.post('/users/transfer-subscription', {
        transfereeEmail: email,
        subId
      })
      setIsSuccess(true)
      return
    } catch (err: any) {
      if ([TRANSFER_ERROR_CODE.TRANSFEREE_SAME_AS_TRANSFERRER, TRANSFER_ERROR_CODE.TRANSFEREE_EXISTS, TRANSFER_ERROR_CODE.EXISTING_TRANSFER_PENDING, TRANSFER_ERROR_CODE.MIN_DURATION_NOT_MET, ERROR_CODE.INVALID_EMAIL].includes(err?.response?.data?.errorCode)) {
        setErrorMessage(err?.response?.data?.message)
      } else {
        setErrorMessage('Error')
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        overflowY: 'scroll'
      }}
      open={isShowBackdrop}
    >
      <Paper
        sx={{
          maxWidth: '50rem',
          minWidth: '2rem',
          backgroundColor: 'white',
          borderRadius: '20px',
          mt: 5
        }}
      >
        <Container
          sx={{
            py: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            rowGap: 2
          }}
        >
          <Typography variant="subtitle2" color="error">
            {errorMessage}
          </Typography>
          {isSuccess && (
            <Box display="flex" flexDirection="column" rowGap={2} alignItems="center">
              <Typography>Hurray! Your transfer subscription mail has been sent to</Typography>
              <Typography sx={{ color: 'blue', fontWeight: 'bold', textAlign: 'center' }}>{email}</Typography>
              <Button
                variant="contained"
                role="button"
                sx={{
                  backgroundColor: '#2a7c6f',
                  px: 5,
                  borderRadius: 5,
                  width: '15rem',
                  ':hover': { backgroundColor: 'white', color: '#2a7c6f' }
                }}
                onClick={() => {
                  setEmail('')
                  setIsShowBackdrop(false)
                  setIsShowTransferBackdrop(false)
                }}
              >
                Awesome!
              </Button>
              <Typography variant="subtitle2">*Remind them to check their inbox / spam folder to accept your invite!</Typography>
            </Box>
          )}
          {!isSuccess && (
            <>
              <Typography>Are you sure you wish to initiate a transfer of your subscription to </Typography>
              <Typography sx={{ color: 'blue', fontWeight: 'bold', textAlign: 'center' }}>{email}</Typography>
              <Box display="flex" justifyContent="space-around">
                <Button
                  variant="contained"
                  role="button"
                  sx={{
                    px: 5,
                    borderRadius: 5,
                    width: '15rem'
                  }}
                  onClick={() => handleSubmitEmail(subId)}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress sx={{ ml: 1 }} size="1rem" />
                    </Box>
                  ) : (
                    'Yes'
                  )}
                </Button>
                <Button
                  variant="text"
                  color="error"
                  sx={{ width: '15rem' }}
                  onClick={() => {
                    setErrorMessage('')
                    setIsShowBackdrop(false)
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </Box>
            </>
          )}
        </Container>
      </Paper>
    </Backdrop>
  )
}
