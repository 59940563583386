import { AxiosResponse } from 'axios'

import { httpGet, httpPatch, httpPost } from './helpers/http'

export enum QuestionType {
  MCQ = 'mcq',
  Fill = 'fill'
}

export interface IQuestion {
  question: string
  type: QuestionType
  choices?: string[] // only for type = 'mcq'
  correctChoiceIndex?: number // only for type = 'mcq'
  possibleAnswers?: string[] // only for type = 'fill'
  explanation: string
}

export enum QuizCategory {
  PAPER_1 = 'Paper 1',
  PAPER_2 = 'Paper 2',
  FREE = 'Free',
  FREE2 = 'Free2'
}

export interface IQuiz {
  _id: string
  topic: string
  category: QuizCategory
  imageLink: string
  noOfQuestions: number
  questionsCompleted: number
  score: null | number
}

export interface IUserQuiz {
  _id: string
  topic: string
  questions: IQuestion[]
  answers: (string | number)[]
}
export interface IUserSubscription {
  startUnixS: number
  endUnixS: number
  items: {
    price: number
    currency: string
  }[]
}

export class Api {
  /**
    OTP
  */
  static async sendOtp(email: string) {
    const url = `/otp/send`
    return httpPost(url, {}, { email })
  }

  static async verifyOtp(userId: string, hash: string, otp: string) {
    const url = `/otp/verify`
    return httpPost(url, {}, { userId, hash, otp })
  }

  static async refreshOtp() {
    const url = `/otp/refresh`
    return httpGet(url)
  }

  static async logout() {
    const url = `/otp/logout`
    return httpGet(url, {}, {})
  }

  /**
    User Quizzes
  */

  static async getUserQuizzes(): Promise<AxiosResponse<{ quizzes: IQuiz[] }>> {
    const url = `/user-quizzes`
    return httpGet(url, {}, {})
  }

  static async getUserQuiz(quizId: string): Promise<AxiosResponse<IUserQuiz>> {
    const url = `/user-quizzes/${quizId}`
    return httpGet(url, {}, {})
  }

  static async createUserQuiz(): Promise<AxiosResponse<IQuiz>> {
    const url = `/user-quizzes`
    return httpGet(url, {}, {})
  }

  static async updateUserQuiz(
    quizId: string,
    data: { [key: string]: any }
  ): Promise<AxiosResponse<any>> {
    const url = `/user-quizzes/${quizId}`
    return httpPatch(url, {}, data)
  }
  /**
   * Quiz
   */
  static async getQuizzes(): Promise<AxiosResponse<{ quizzes: IQuiz[] }>> {
    const url = `/quiz`
    return httpGet(url, {}, {})
  }

  static async insertQuizzes(
    quizzes: { topic: string; category: QuizCategory; questions: IQuestion[] }[]
  ) {
    const url = `/quiz`
    return httpPost(url, {}, { quizzes })
  }
  /**
   * Lead
   */
  static async insertLead(email: string) {
    const url = `/leads`
    return httpPost(url, {}, { email })
  }
  static async updateLead(email: string, body: Record<string, unknown>) {
    const url = `/leads`
    return httpPatch(url, {}, { email, ...body })
  }
  /**
   * Session
   */
  static async insertSession(
    sessionId: string,
    quizCategory: 'Free' | 'Free2'
  ) {
    const url = `/sessions`
    return httpPost(url, {}, { sessionId, quizCategory })
  }
  static async updateSession(body: Record<string, unknown>) {
    const url = `/sessions`
    return httpPatch(url, {}, body)
  }
  /**
   * Quiz Comment
   */
  static async insertQuizComment(obj: any) {
    const url = `/quiz-comments`
    return httpPost(url, {}, obj)
  }
  /**
   * Stripe Plans
   */
  static async getStripePlans({ promoCode }: { promoCode: string | null }) {
    let url = `/stripe/plans`
    if (promoCode) {
      url += `?promo=${promoCode}`
    }
    return httpGet(url)
  }
}
