import { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import { Container } from '@mui/system'
import axios, { AxiosError } from 'axios'

import { Api } from '../Api'
import { MainNav } from '../components/Layout/MainNav'
import { PRIMARY_COLOR, RESEXAMSG_PRICING } from '../constants'
import { validateEmail, validateOtp } from '../helpers/inputValidators'
import { useAuth } from '../hooks/useAuth'

export const Login = () => {
  const { auth, setAuth } = useAuth()
  const emailRef = useRef<HTMLInputElement>(null)
  const otpRef = useRef<HTMLInputElement>(null)
  const [successMessage, setSuccessMessage] = useState('')

  const navigate = useNavigate()

  const [userId, setUserId] = useState('')
  const [hash, setHash] = useState('')
  const [pageNo, setPageNo] = useState(1)
  /**
   * Email
   */
  const [email, setEmail] = useState('')
  const [isSubmittingEmail, setIsSubmittingEmail] = useState(false)
  const [isEmailInvalid, setIsEmailInvalid] = useState(false)
  const [invalidEmailText, setInvalidEmailText] = useState<string | null>(null)

  /**
   * OTP
   */
  const [otp, setOtp] = useState('')
  const [isSubmittingOtp, setIsSubmittingOtp] = useState(false)
  const [isOtpInvalid, setIsOtpInvalid] = useState(false)
  const [invalidOtpText, setInvalidOtpText] = useState<string | null>(null)

  const [searchParams] = useSearchParams()

  useEffect(() => {
    emailRef.current?.focus()
    if (auth.accessToken) {
      navigate('/')
    }

    if (searchParams.get('payment_success') === 'true') {
      // set payment success message
      setSuccessMessage('Payment is successful. Please proceed to login.')
    }
  }, [])

  useEffect(() => {
    if (pageNo === 2) {
      otpRef.current?.focus()
    }
  }, [pageNo])

  const handleSubmitEmail = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setIsSubmittingEmail(true)
    setIsEmailInvalid(false)
    setInvalidEmailText(null)

    // validate email
    if (!validateEmail(email)) {
      setIsEmailInvalid(true)
      setInvalidEmailText('Please enter a valid email.')
      setIsSubmittingEmail(false)
      return
    }

    try {
      const { data } = await Api.sendOtp(email.toLowerCase().trim())
      // set hash and userId
      setUserId(data.userId)
      setHash(data.hash)

      setPageNo(2)
    } catch (err: unknown) {
      console.error(err)
      const error = err as Error | AxiosError
      if (axios.isAxiosError(error) && error?.response?.data?.message) {
        setInvalidEmailText(error.response.data.message)
      } else {
        setInvalidEmailText('Something went wrong. Please try again later.')
      }
      setIsEmailInvalid(true)
    }
    setIsSubmittingEmail(false)
  }
  const handleSubmitOtp = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setIsSubmittingOtp(true)
    setIsOtpInvalid(false)
    setInvalidOtpText(null)

    // validate otp
    if (!validateOtp(otp)) {
      setIsOtpInvalid(true)
      setInvalidOtpText('Please enter a valid OTP.')
      setIsSubmittingOtp(false)
      return
    }

    try {
      const { status, data } = await Api.verifyOtp(userId, hash, otp)

      if (status === 200) {
        setAuth({
          roles: data.roles,
          accessToken: data.accessToken,
          referralCode: data.referralCode
        })
        navigate('/')
      } else {
        setIsOtpInvalid(true)
        setInvalidOtpText('Something went wrong. Please try again later.')
      }
    } catch (err: unknown) {
      console.error(err)
      const error = err as Error | AxiosError
      if (axios.isAxiosError(error) && error.response) {
        const errorCode = error.response.data?.errorCode
        if (errorCode === 'OTP_EXPIRED') {
          setInvalidOtpText('OTP has expired. Please retry.')
        } else if (errorCode === 'OTP_INCORRECT') {
          setInvalidOtpText('OTP is incorrect.')
        }
      } else {
        setInvalidOtpText('Something went wrong. Please try again later.')
      }
      setIsOtpInvalid(true)
    }

    setIsSubmittingOtp(false)
  }

  return (
    <Box sx={{ pb: 30 }}>
      <MainNav />
      <Container>
        {successMessage && (
          <Alert severity="success">
            <Typography variant="body1">{successMessage}</Typography>
          </Alert>
        )}
        {process.env.REACT_APP_IS_SHOW_MAINTENANCE_MSG === 'true' && (
          <Alert severity="warning" sx={{ mt: 1 }}>
            <Typography variant="body1">We are currently performing a minor maintenance to serve you better. Please try again later. Thank you for your kind understanding.</Typography>
          </Alert>
        )}
      </Container>
      {pageNo === 1 && (
        <Box
          component="form"
          sx={{
            mt: 5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            maxWidth: 250,
            mx: 'auto'
          }}
          onSubmit={handleSubmitEmail}
        >
          <TextField type="email" variant="outlined" label="Email" value={email} error={isEmailInvalid} helperText={invalidEmailText} onChange={(e) => setEmail(e.target.value)} inputRef={emailRef} fullWidth />
          <Button
            variant="contained"
            disabled={isSubmittingEmail}
            sx={{
              px: 8
            }}
            type="submit"
          >
            {isSubmittingEmail ? (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress sx={{ ml: 1 }} size="1rem" />
              </Box>
            ) : (
              <>
                <Typography variant="body2">Login</Typography>
              </>
            )}
          </Button>
          {!isSubmittingEmail && (
            <Typography variant="body2" component="a" href={RESEXAMSG_PRICING} sx={{ textDecoration: 'none' }}>
              Don&lsquo;t have an account? Sign Up
            </Typography>
          )}
        </Box>
      )}
      {pageNo === 2 && (
        <Box
          component="form"
          sx={{
            mt: 5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2
          }}
          onSubmit={handleSubmitOtp}
        >
          <Typography component="span">OTP has been sent to your email.</Typography>
          <TextField variant="outlined" label="OTP" value={otp} onChange={(e) => setOtp(e.target.value)} error={isOtpInvalid} helperText={invalidOtpText} inputRef={otpRef} />
          <Button
            variant="contained"
            disabled={isSubmittingOtp}
            sx={{
              px: 8
            }}
            type="submit"
          >
            {isSubmittingOtp ? (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress sx={{ ml: 1 }} size="1rem" />
              </Box>
            ) : (
              <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                Submit
              </Typography>
            )}
          </Button>
        </Box>
      )}
    </Box>
  )
}
