import axios from 'axios'

const API_PREFIX = '/api/v1'

export default axios.create({
  baseURL: API_PREFIX
})

export const axiosPrivate = axios.create({
  baseURL: API_PREFIX,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
})
