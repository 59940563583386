import axios from 'axios'

import { useAuth } from './useAuth'

export const useRefreshToken = () => {
  const { setAuth } = useAuth()

  const refresh = async () => {
    const response = await axios.get('/api/v1/otp/refresh', {
      withCredentials: true
    })
    setAuth((prev: any) => {
      // console.log(JSON.stringify(prev))
      // console.log(response.data.accessToken)
      return {
        ...prev,
        roles: response.data.roles,
        accessToken: response.data.accessToken,
        referralCode: response.data.referralCode
      }
    })
    return response.data.accessToken
  }

  return refresh
}
