import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import { v4 as uuid } from 'uuid'

const accordions: React.ReactElement[] = []
const questions = [
  {
    question: 'How will I be paid?',
    answer:
      'Referral earnings will be credited to the card you that you’ve used to purchase your plan.'
  },
  {
    question: 'When will I be paid?',
    answer:
      'Payouts will be on the second week of the following month. (I.e. If your referral makes a successful purchase on 23 March, you will receive 10% of his/her purchase value on 2nd week of April)'
  },
  {
    question: 'Is there a referral limit?',
    answer: (
      <Typography variant="body1" component="p">
        Absolutely not! The more you refer the more we will shared revenue you
        will receive. It&lsquo;s a win-win for the referred party who benefits
        as well.
      </Typography>
    )
  },
  {
    question: 'Am I eligible if i unsubscribe?',
    answer: (
      <Typography variant="body1" component="p">
        Yes, save your referral link or code. Even after your subscription ends,
        you can still continue to refer & earn comission. Sky is the limit!
      </Typography>
    )
  }
]

questions.forEach(({ question, answer }) => {
  accordions.push(
    <Accordion>
      <AccordionSummary id={uuid()} expandIcon={<ExpandMoreIcon />}>
        <Typography>{question}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{answer}</Typography>
      </AccordionDetails>
    </Accordion>
  )
})

export const ReferralFAQ = () => {
  return <Box>{accordions}</Box>
}
