import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box, Button, Paper, Typography } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Container from '@mui/material/Container'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import axios from 'axios'

export const UnsubEmailBackdrop = ({
  isShowBackdrop,
  setIsShowBackdrop
}: {
  isShowBackdrop: any
  setIsShowBackdrop: any
}) => {
  const [searchParams] = useSearchParams()
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const handleUnsub = async () => {
    const url = `/api/v1/leads/unsub`
    setIsSuccess(false)
    setIsError(false)

    try {
      await axios.put(url, { id: searchParams.get('id') })
      setIsSuccess(true)
    } catch (err) {
      setIsError(true)
    }
  }

  return (
    <Backdrop open={isShowBackdrop}>
      <Container maxWidth="sm">
        <Grid2 sm={3}>
          <Paper
            sx={{
              p: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              rowGap: 4
            }}
          >
            {isSuccess && (
              <Typography variant="subtitle2" color="green" my={0} py={0}>
                Success
              </Typography>
            )}
            {isError && (
              <Typography variant="subtitle2" color="red" my={0} py={0}>
                Something went wrong
              </Typography>
            )}
            <Typography fontWeight="bold">
              Unsubscribe from resexam.sg marketing campaigns?
            </Typography>
            <Typography variant="body2">
              You will no longer receive marketing campaigns, which include real
              estate regulatory updates, examination tips and more. *If you have
              purchased a plan, only essential notifications regarding your plan
              will be sent to you
            </Typography>
            <Box display="flex" width="100%" justifyContent="space-evenly">
              <Button
                variant="contained"
                color="inherit"
                onClick={() => {
                  setIsSuccess(false)
                  setIsError(false)
                  setIsShowBackdrop(false)
                }}
              >
                Not for now
              </Button>
              <Button sx={{ color: 'black' }} onClick={handleUnsub}>
                Yes, unsubscribe
              </Button>
            </Box>
          </Paper>
        </Grid2>
      </Container>
    </Backdrop>
  )
}
