import { createContext, useState } from 'react'
import PropTypes from 'prop-types'

import { Api } from '../Api'

// extract cookies
// const cookies = new Cookies()

// export const AuthContext = createContext({
//   isAuth: null,
//   logout: async () => {
//     await Api.logout()
//     console.log('successfully logout')
//   }
// })

export const AuthContext = createContext<{
  auth: { accessToken?: string; roles?: number[]; referralCode?: string }
  setAuth: any
}>({
  auth: {},
  setAuth: null
})

export const AuthProvider = ({ children }: any) => {
  const [auth, setAuth] = useState<{
    accessToken?: string
    roles?: number[]
    referralCode?: string
  }>({})
  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  )

  // console.log('AT AuthProvider')
  // useEffect(() => {
  //   console.error('At AuthProvider!')
  //   // get non http-only cookies
  //   const accessToken = cookies.get('authSession')
  //   const refreshToken = cookies.get('refreshTokenID')

  //   // set isAuth
  //   const isAuth = accessToken && refreshToken
  //   setIsAuth(isAuth)

  //   // attempt to refresh access token if refresh token is available
  //   if (!accessToken && refreshToken) {
  //     axios
  //       .get('/api/v1/otp/refresh', {
  //         withCredentials: true
  //       })
  //       .then((res) => {
  //         console.log(res.data)
  //         window.location.reload()
  //       })
  //       .catch((error) => {
  //         setIsAuth(false)
  //         console.log('error')
  //         console.log(error.response)
  //       })
  //   }
  // }, [])

  // const authContext = {
  //   isAuth,
  //   logout: async () => {
  //     await Api.logout()
  //     setIsAuth(false)
  //     console.log('successfully logout')
  //   }
  // }

  // return (
  //   <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  // )
}

AuthProvider.propTypes = {
  children: PropTypes.node
}
