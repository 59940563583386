import { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { Box, Button, Container, Typography } from "@mui/material"
import moment from "moment"
import Papa from "papaparse"

import { MainNav } from "../components/Layout/MainNav"
import { QuizCommentTable } from "../components/QuizCommentTable"
import { useAxiosPrivate } from "../hooks/useAxiosPrivate"

export interface IQuizComment {
  quizQuestionNo: number
  quizCategory: string
  quizTopic: string
  quizQuestion: string
  comment: string
}

export const QuizComment = () => {
  const axiosPrivate = useAxiosPrivate()
  const [quizComments, setQuizComments] = useState<IQuizComment[]>([])
  const [startDate, setStartDate] = useState<Date | null>(moment().subtract(1, "months").toDate())
  const [endDate, setEndDate] = useState<Date | null>(new Date())

  useEffect(() => {
    const getQuizComments = async () => {
      // require auth
      try {
        const url = "/quiz-comments"
        const { data } = await axiosPrivate.get(url)

        if (Array.isArray(data?.quizComments)) {
          setQuizComments(data.quizComments)
        }
      } catch (err: any) {
        console.error(err.message)
      }
    }

    getQuizComments()
  }, [])

  const handleDownloadCSV = async () => {
    const startUnixMs = moment(startDate).valueOf()
    const endUnixMs = moment(endDate).valueOf()
    const { data } = await axiosPrivate.get(`/quiz-comments?startUnixMs=${startUnixMs}&endUnixMs=${endUnixMs}`)
    const parsedQuizComments = parseQuizComments(data.quizComments)
    const csv = Papa.unparse(parsedQuizComments)
    const blob = new Blob([csv], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")
    const startDateFormatted = moment(startDate).format("YYMMDD")
    const endDateFormatted = moment(endDate).format("YYMMDD")
    link.download = `${startDateFormatted}-${endDateFormatted}-quiz-comments.csv`
    link.href = url
    link.click()
  }

  const parseQuizComments = (quizComments: any[]) => {
    return quizComments.map(({ createdTime, quizCategory, quizQuestion, quizQuestionNo, user, comment }) => {
      return {
        createdAt: moment(createdTime).format("YYYY-MM-DD HH:mm:ss"),
        quizCategory,
        quizQuestion,
        quizQuestionNo,
        email: user?.email,
        comment,
      }
    })
  }

  return (
    <Box>
      <MainNav />
      <Container sx={{ mt: 5 }}>
        <Typography variant="h4" sx={{ color: "rgb(42, 124, 111)", mb: 2 }}>
          Quiz Feedback
        </Typography>
        <Box display="flex" sx={{ my: 5 }} alignItems="center" columnGap={3}>
          <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="yyyy-MM-dd" />
          <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} dateFormat="yyyy-MM-dd" />
          <Button variant="contained" size="small" onClick={async () => await handleDownloadCSV()}>
            Export CSV
          </Button>
        </Box>
        <QuizCommentTable quizComments={quizComments} />
      </Container>
    </Box>
  )
}
