import { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import moment from 'moment'
import { v4 as uuid } from 'uuid'

import NavBar from '../../components/Layout/NavBar'
import { ReferralFAQ } from '../../components/ReferralFAQ'
import { useAuth } from '../../hooks/useAuth'
import { useAxiosPrivate } from '../../hooks/useAxiosPrivate'

export const PAYOUT_STATUS = {
  PENDING: 'pending',
  COMPLETED: 'completed'
} as const
export type PAYOUT_STATUS = (typeof PAYOUT_STATUS)[keyof typeof PAYOUT_STATUS]

export interface IReferral {
  product: string
  purchaseAmountCents: number
  rewardAmountCents: number
  payoutStatus: PAYOUT_STATUS
  createdTime: number // in unix ms
}

export default function CustomerReferral() {
  const axiosPrivate = useAxiosPrivate()
  const [referrals, setReferrals] = useState<IReferral[]>([])
  const { auth } = useAuth()

  useEffect(() => {
    const getUserSubscriptions = async () => {
      // require auth
      try {
        const url = '/users/referrals'
        const { data } = await axiosPrivate.get(url)

        if (Array.isArray(data?.data)) {
          setReferrals(data.data)
        }
      } catch (err: any) {
        console.error(err.message)
      }
    }

    getUserSubscriptions()
  }, [axiosPrivate])

  const referralLink = `https://www.resexam.site/packs?promo=${auth?.referralCode || ''}`
  const referralMessage = `RES Exam SG provides over 1000+ challenging questions based on latest exam format and industry changes. Practice on any device, any time with instant feedback and explanation. Use my referral link for a 5% discount: ${referralLink}`

  const successfulReferrals = referrals.length
  const totalEarningsCents = referrals.reduce((a, b) => {
    a += b.rewardAmountCents
    return a
  }, 0)
  const totalEarningsDollars = (totalEarningsCents / 100).toFixed(2)

  return (
    <Box mb={20}>
      <NavBar title="Refer & Earn" navigateTo="/profile" />
      <Container sx={{ mt: 5 }}>
        <Typography fontWeight="bold" variant="h4">
          Refer & Earn Cash
        </Typography>
        <Typography fontWeight="bold" variant="h5" mt={5}>
          How does it work?
        </Typography>
        <Typography>Share your referral code. If your friend uses your referral code to make a purchase:</Typography>
        <Typography mt={3}>
          You will earn a <strong>10% commission</strong> in cash credited to your card based on each referral&lsquo;s purchase. (e.g. For every $49.90 pack successfully referred, you will receive close to $5. For a $89.90 plan, you will receive close to $9 per referral)
        </Typography>
        <Typography mt={3}>
          Your referral receives a <strong>5%</strong> discount.
        </Typography>
        <Typography fontWeight="bold" variant="h5" mt={5} gutterBottom>
          Start Earning
        </Typography>
        <Typography>Click on the &lsquo;Copy Referral Code&lsquo; button and paste it on any chat (Whatsapp, Telegram or any social media platforms)</Typography>

        <Paper sx={{ mt: 5, p: 5 }} variant="elevation" elevation={3}>
          <Grid container rowGap={5}>
            <Grid xs={12} md={6}>
              <div
                style={{
                  height: 'auto',
                  margin: '0 auto',
                  maxWidth: 192,
                  width: '100%'
                }}
              >
                <QRCode size={256} style={{ height: 'auto', maxWidth: '100%', width: '100%' }} value={referralLink} viewBox={`0 0 256 256`} />
              </div>
            </Grid>
            <Grid xs={12} md={6} display="flex" direction="column" justifyContent="center" gap={3}>
              <Button onClick={() => navigator.clipboard.writeText(referralMessage)} variant="contained" color="info">
                Copy Referral Message + Link <ContentCopyIcon fontSize="small" sx={{ ml: 1 }} />
              </Button>
            </Grid>
          </Grid>
        </Paper>

        <Typography fontWeight="bold" variant="h5" mt={5} mb={3} gutterBottom>
          FAQ
        </Typography>

        <ReferralFAQ />

        <Typography fontWeight="bold" variant="h5" mt={5} mb={3} gutterBottom>
          Referrals
        </Typography>

        <Grid display="flex" mb={3} container rowGap={3} columnGap={3}>
          <Grid xs={12} sm={5} item>
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 5,
                py: 3
              }}
              variant="outlined"
            >
              <Typography variant="h6">Successful Referrals</Typography>
              <Typography variant="h5" fontWeight="bold">
                {successfulReferrals}
              </Typography>
            </Paper>
          </Grid>
          <Grid xs={12} sm={5} item>
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 5,
                py: 3
              }}
              variant="outlined"
            >
              <Typography variant="h6">Total Earnings</Typography>
              <Typography variant="h5" fontWeight="bold">
                ${totalEarningsDollars}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {referrals.length > 0 && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Referee&lsquo;s Purchase</TableCell>
                  <TableCell>Purchase Amount</TableCell>
                  <TableCell>My Commission</TableCell>
                  <TableCell>Payout Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {referrals.map(({ product, payoutStatus, purchaseAmountCents, rewardAmountCents, createdTime }, i) => (
                  <TableRow key={uuid()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {i + 1}
                    </TableCell>
                    <TableCell>{moment.unix(createdTime / 1000).format('DD MMM YY')}</TableCell>
                    <TableCell>{product}</TableCell>
                    <TableCell>{`$${(purchaseAmountCents / 100).toFixed(2)}`}</TableCell>
                    <TableCell>{`$${(rewardAmountCents / 100).toFixed(2)}`}</TableCell>
                    <TableCell>
                      {payoutStatus === PAYOUT_STATUS.COMPLETED && <Typography color="green">Paid</Typography>}
                      {payoutStatus === PAYOUT_STATUS.PENDING && <Typography color="orange">Pending</Typography>}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </Box>
  )
}
