import AllInclusiveIcon from '@mui/icons-material/AllInclusive'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import {
  Backdrop,
  Box,
  Button,
  Container,
  Grid,
  Typography
} from '@mui/material'
import { v4 as uuid } from 'uuid'

import { RESEXAMSG_PRICING } from '../constants'
const Paragraph = ({
  title,
  body,
  icon
}: {
  title: string
  body: string
  icon: any
}) => {
  return (
    <Grid
      container
      sx={{
        py: 1,
        px: 2
      }}
    >
      <Grid item xs={2} sm={1} textAlign="center">
        {icon}
      </Grid>
      <Grid item xs={10} sm={11}>
        <Typography variant="body1" sx={{ color: '#2a7c6f' }}>
          <strong>{title}</strong>
        </Typography>
        <Typography variant="body1" sx={{ color: '#2a7c6f' }}>
          {body}
        </Typography>
      </Grid>
    </Grid>
  )
}

const points = [
  {
    title: 'Unlimited Practice',
    body: 'Unlock all topics from Paper 1 & 2 clustered by topics. Over 1000+ questions in MCQ and case study format, structured to help you recall your learning and pass your exam.',
    icon: <AllInclusiveIcon />
  },
  {
    title: 'Practice anywhere, anytime',
    body: "Available on both desktop and mobile. Practice in the train, office or even on your bed. Need to pause? We'll save your progress.",
    icon: <LightbulbIcon />
  },
  {
    title: 'Proven to help you pass',
    body: 'Helped over 70% of candidates pass on first attempt. Our question pack is also constantly updated to make sure you get the latest!',
    icon: <TaskAltIcon />
  }
]

export const OfferBackdrop = ({
  isShowOfferBackdrop,
  setIsShowOfferBackdrop
}: {
  isShowOfferBackdrop: boolean
  setIsShowOfferBackdrop: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        overflowY: 'scroll',
        pt: 30
      }}
      open={isShowOfferBackdrop}
    >
      <Container
        sx={{
          py: 3,
          backgroundColor: '#fcfbf7',
          color: 'black'
        }}
        disableGutters={true}
        maxWidth="md"
      >
        <Grid container gap={2}>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" color="#2a7c6f">
              <strong>Access the full RES Exam Pack</strong>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            textAlign="center"
            sx={{
              background:
                'linear-gradient(to right, #3a1c71, #d76d77, #ffaf7b)',
              color: 'white',
              py: 1
            }}
          >
            <Typography variant="h6" letterSpacing={2}>
              Limited Time Offer
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="center" sx={{ color: 'red' }}>
            <Box display="flex" justifyContent="center">
              <Typography
                sx={{ textDecoration: 'line-through', mr: 1 }}
                variant="h4"
              >
                $99
              </Typography>
              <Box>
                <Typography
                  sx={{
                    borderBottom: '#2a7c6f 2px solid',
                    pb: 2
                  }}
                  variant="h4"
                >
                  $49.90
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#2a7c6f' }}>
                  3 months access
                </Typography>
              </Box>
            </Box>
          </Grid>
          {points.map(({ title, body, icon }) => {
            return (
              <Paragraph key={uuid()} title={title} body={body} icon={icon} />
            )
          })}
          <Grid item xs={12} textAlign="center">
            <a
              href={RESEXAMSG_PRICING}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant="contained"
                sx={{ backgroundColor: '#2a7c6f', px: 5 }}
              >
                Get RES Exam Pack!
              </Button>
            </a>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="text"
              sx={{ color: 'black' }}
              onClick={() => {
                setIsShowOfferBackdrop(false)
              }}
            >
              I&lsquo;ll get it later
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Backdrop>
  )
}
