export const COLORS = ['#ff6961', '#ffb480', '#f8f38d', '#42d6a4', '#08cad1', '#59adf6', '#9d94ff', '#c780e8']
export enum ROLES {
  Customer = 2001,
  Admin = 3001
}
export const STRIPE_CHECKOUT_LINK = process.env.REACT_APP_STRIPE_CHECKOUT_LINK || '#'

export const ERROR_CODE = {
  INVALID_EMAIL: 'invalid_email',
  DUPLICATE_QUIZ: 'duplicate_quiz'
} as const
export type ERROR_CODE = (typeof ERROR_CODE)[keyof typeof ERROR_CODE]

export const TRANSFER_ERROR_CODE = {
  TRANSFEREE_SAME_AS_TRANSFERRER: 'transferee_same_as_transferrer',
  TRANSFEREE_EXISTS: 'transferee_exists',
  EXISTING_TRANSFER_PENDING: 'existing_transfer_pending',
  MIN_DURATION_NOT_MET: 'min_duration_not_met'
} as const
export type TRANSFER_ERROR_CODE = (typeof TRANSFER_ERROR_CODE)[keyof typeof TRANSFER_ERROR_CODE]

export const GREEN_COLOR = 'rgb(42, 124, 111)'
export const GREEN_COLOR_2 = 'rgb(31, 47, 22)'
export const PLAN_CATEGORY = {
  COMBINED: 'combined',
  PAPER_1: 'paper_1',
  PAPER_2: 'paper_2'
} as const
export type PLAN_CATEGORY = (typeof PLAN_CATEGORY)[keyof typeof PLAN_CATEGORY]

export const RESEXAMSG_FAQ = 'https://resexam.sg/#faq'
export const RESEXAMSG_PRICING = 'https://resexam.sg/#pricing'

export const PRIMARY_COLOR = '#395B50'
export const PRIMARY_FONT = 'sans-serif'
