import Box from '@mui/material/Box'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import moment from 'moment'

import { IQuizComment } from '../pages/QuizComment'

export const QuizCommentTable = ({
  quizComments
}: {
  quizComments: IQuizComment[]
}) => {
  const columns: GridColDef[] = [
    {
      field: 'createdTime',
      headerName: 'Date',
      valueFormatter: ({ value }) => {
        return moment.unix(value / 1000).format('Do MMM hh:mm A')
      },
      width: 150
    },
    {
      field: 'user',
      headerName: 'Email',
      valueFormatter: ({ value }) => (value?.email ? value.email : 'Unknown'),
      width: 150
    },
    {
      field: 'quizCategory',
      headerName: 'Category'
    },
    {
      field: 'quizTopic',
      headerName: 'Topic',
      width: 200
    },
    {
      field: 'quizQuestionNo',
      headerName: 'Qn No.',
      type: 'string',
      width: 80
    },
    {
      field: 'quizQuestion',
      headerName: 'Question',
      width: 400
    },
    {
      field: 'comment',
      headerName: 'Comment',
      width: 1000
    }
  ]

  return (
    <Box sx={{ height: 800, width: '100%' }}>
      <DataGrid
        getRowId={(row) => row._id}
        rows={quizComments}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[10, 20, 30]}
      />
    </Box>
  )
}
