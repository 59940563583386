import { Box, Button, Paper, Typography } from "@mui/material"
import Backdrop from "@mui/material/Backdrop"
import Container from "@mui/material/Container"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"

export const PreUnsubBackdrop = ({
  isShowBackdrop,
  handleYesClick,
  handleNoClick,
}: {
  isShowBackdrop: any
  handleYesClick: any
  handleNoClick: any
}) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        overflowY: "scroll",
      }}
      open={isShowBackdrop}
    >
      <Container maxWidth="sm">
        <Grid2 sm={3}>
          <Paper
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              rowGap: 4,
            }}
          >
            <Typography variant="body2" textAlign="center">
              Would you like to sell off or transfer your account to other users
              instead?
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap={2}
            >
              <Box>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={handleYesClick}
                  sx={{
                    px: 5,
                    borderRadius: 5,
                    color: "white",
                  }}
                >
                  Yes
                </Button>
              </Box>
              <Box>
                <Button
                  sx={{ color: "red" }}
                  variant="text"
                  onClick={handleNoClick}
                >
                  Continue to unsubscribe
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid2>
      </Container>
    </Backdrop>
  )
}
