import { useEffect } from "react"
import ReactGA from "react-ga"
import { BrowserRouter, Route, Routes } from "react-router-dom"

import { PersistLogin } from "./components/PersistLogin"
import { RequireAuth } from "./components/RequireAuth"
import { AuthProvider } from "./context/AuthProvider"
import CustomerReferral from "./pages/customer/CustomerReferral"
import CustomerSubscription from "./pages/customer/CustomerSubscription"
import { Profile } from "./pages/customer/Profile"
import { EmailPreferences } from "./pages/EmailPreferences"
import { Free } from "./pages/Free"
import { Free2 } from "./pages/Free2"
import { Home } from "./pages/Home"
import { Login } from "./pages/Login"
import { Quiz } from "./pages/Quiz"
import { QuizComment } from "./pages/QuizComment"
import { SubscriptionPlan } from "./pages/SubscriptionPlan"
import { SurveyResponse } from "./pages/SurveyResponse"
import { Unauthorized } from "./pages/Unauthorized"
import { Upload } from "./pages/Upload"
import { User } from "./pages/User"

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || ""
const ROLES = {
  Customer: 2001,
  Admin: 3001,
}

ReactGA.initialize(TRACKING_ID)

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          {/* public routes */}
          <Route element={<PersistLogin />}>
            <Route path="/" element={<Home />} />
            <Route path="/quizzes/:id" element={<Quiz />} />
            <Route path="/login" element={<Login />} />
            <Route path="/packs" element={<SubscriptionPlan />} />
            <Route path="/email-preferences" element={<EmailPreferences />} />
            <Route element={<RequireAuth allowedRoles={[ROLES.Customer]} />}>
              <Route path="/profile" element={<Profile />} />
              <Route path="/profile/subscriptions" element={<CustomerSubscription />} />
              <Route path="/profile/referrals" element={<CustomerReferral />} />
            </Route>
          </Route>
          <Route path="/free" element={<Free />} />
          <Route path="/free2" element={<Free2 />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          {/* admin routes */}
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
              <Route path="/upload" element={<Upload />} />
              <Route path="/users" element={<User />} />
              <Route path="/quiz-feedback" element={<QuizComment />} />
              <Route path="/survey-responses" element={<SurveyResponse />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  )
}

export default App
