import { useState } from 'react'
import { Box, Typography } from '@mui/material'

import { UnsubEmailBackdrop } from '../components/UnsubEmailBackdrop'

export function EmailPreferences() {
  const [isShowBackdrop, setIsShowBackdrop] = useState(false)

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Typography
        variant="subtitle1"
        onClick={() => setIsShowBackdrop(true)}
        sx={{
          cursor: 'pointer',
          color: 'grey'
        }}
      >
        Manage <u>Campaign Preferences</u>
      </Typography>
      <UnsubEmailBackdrop
        isShowBackdrop={isShowBackdrop}
        setIsShowBackdrop={setIsShowBackdrop}
      />
    </Box>
  )
}
