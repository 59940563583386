import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt"
import { Alert, Box, Container, Grid, Typography } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import { v4 as uuid } from "uuid"

import { Api } from "../Api"
import { ExamPack } from "../components/ExamPack"
import { MainNav } from "../components/Layout/MainNav"
import { PLAN_CATEGORY, PRIMARY_COLOR } from "../constants"

export const SubscriptionPlan = () => {
  const [value, setValue] = useState<PLAN_CATEGORY>(PLAN_CATEGORY.COMBINED)
  const [plans, setPlans] = useState([])
  const [promoCode, setPromoCode] = useState<string | null>(null)
  const [isPromoSuccessful, setIsPromoSuccessful] = useState<boolean | null>(
    null
  )
  const [isLoading, setIsLoading] = useState(true)

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const promoCode = searchParams.get("promo")
    setPromoCode(promoCode)
    const getUserSubscriptions = async () => {
      setIsLoading(true)
      try {
        const { data } = await Api.getStripePlans({ promoCode })
        if (promoCode && data?.data?.length > 0) {
          if (data.data[0].unitAmountPromo) {
            setIsPromoSuccessful(true)
          } else {
            setIsPromoSuccessful(false)
          }
        } else {
          if (promoCode) {
            setIsPromoSuccessful(false)
          }
        }
        setPlans(data.data || [])
      } catch (err: any) {
        console.error(err.message)
      } finally {
        setIsLoading(false)
      }
    }

    getUserSubscriptions()
  }, [])

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: PLAN_CATEGORY
  ) => {
    setValue(newValue)
  }

  return (
    <Box>
      <MainNav />
      <Container>
        {isPromoSuccessful === true && (
          <Alert severity="success">
            Promo Code - <strong>{promoCode}</strong> successfully applied!
          </Alert>
        )}
        {isPromoSuccessful === false && (
          <Alert severity="error">
            Invalid Promo Code - <strong>{promoCode}</strong>
          </Alert>
        )}
        <Typography color={PRIMARY_COLOR} fontWeight={700} fontSize="56px">
          RES Exam Packs
        </Typography>
        <Typography color={PRIMARY_COLOR}>
          We recommend the combined Paper 1 & 2 for best value and a holistic
          practice. Based on our own experience and feedback from our learners,
          it is common for the RES Exam syllabus to have overlaps. Did we also
          mention? If you&lsquo;ve any questions you can drop us a note,
          we&lsquo;ll do our best to help :)
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "rgb(125,211,198)" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            variant="fullWidth"
          >
            <Tab
              label="Combined"
              icon={<ThumbUpOffAltIcon />}
              iconPosition="end"
              value={PLAN_CATEGORY.COMBINED}
            />
            <Tab label="Paper 1" value={PLAN_CATEGORY.PAPER_1} />
            <Tab label="Paper 2" value={PLAN_CATEGORY.PAPER_2} />
          </Tabs>
        </Box>
        <Box sx={{ py: 3 }}>
          {isLoading && (
            <Box py={5} display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          <Grid container rowGap={10}>
            {plans.map(
              ({
                category,
                displayName,
                interval,
                intervalCount,
                paymentLink,
                savings,
                unitAmount,
                unitAmountPromo,
              }) => {
                if (category === value) {
                  return (
                    <ExamPack
                      key={uuid()}
                      category={category}
                      displayName={displayName}
                      interval={interval}
                      intervalCount={intervalCount}
                      paymentLink={paymentLink}
                      savings={savings}
                      unitAmount={unitAmount}
                      unitAmountPromo={unitAmountPromo}
                    />
                  )
                }
              }
            )}
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}
