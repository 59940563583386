import { useState } from "react"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import {
  Backdrop,
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Chip from "@mui/material/Chip"
import moment from "moment"

import ExistingTransferBackdrop from "../components/ExistingTransferBackdrop"
import TransferCfmBackdrop from "../components/TransferCfmBackdrop"
import { validateEmail, validatePhoneNo } from "../helpers/inputValidators"
import { useAxiosPrivate } from "../hooks/useAxiosPrivate"
import {
  RsgSubStatus,
  Subscription,
} from "../pages/customer/CustomerSubscription"

import { PreUnsubBackdrop } from "./PreUnsubBackdrop"
import RatingBackdrop from "./RatingBackdrop"

const subStatusColorMapping: {
  [key: string]: {
    label: string
    color: "success" | "error" | "warning" | "default"
  }
} = {
  active: { label: "Active", color: "success" },
  unsubscribed: { label: "Unsubscribed", color: "warning" },
  incomplete: { label: "Incomplete", color: "error" },
  incomplete_expired: { label: "Incomplete - Expired", color: "error" },
  trialing: { label: "Trial", color: "default" },
  payment_failed: { label: "Payment Failed", color: "warning" },
  inactive: { label: "Inactive", color: "error" },
  unpaid: { label: "Unpaid", color: "error" },
}

export const SubscriptionCard = ({
  sub,
  handleUnsubscribe,
  handleResubscribe,
}: {
  sub: Subscription
  handleUnsubscribe: any
  handleResubscribe: any
}) => {
  const axiosPrivate = useAxiosPrivate()
  const [rating, setRating] = useState(0)
  const [surveyResponseId, setSurveyResponseId] = useState("")
  const [isShowRatingBackdrop, setIsShowRatingBackdrop] = useState(false)
  const [isShowSurveyBackdrop, setIsShowSurveyBackdrop] = useState(false)
  const [isShowJumpstartBackdrop, setIsShowJumpstartBackdrop] = useState(false)
  const [surveyResponse, setSurveyResponse] = useState("")
  const [jumpstartCareerResponse, setJumpstartCareerResponse] = useState("")
  const [mobileNo, setMobileNo] = useState("")
  const [invaidPhoneNoErrMsg, setInvalidPhoneNoErrMsg] = useState("")

  const [isShowTransferPromptBackdrop, setIsShowTransferPromptBackdrop] =
    useState(false)
  const [isShowTransferBackdrop, setIsShowTransferBackdrop] = useState(false)
  const [isShowTransferCfmBackdrop, setIsShowTransferCfmBackdrop] =
    useState(false)
  const [isShowExistingTransferBackdrop, setIsShowExistingTransferBackdrop] =
    useState(false)

  const [isShowUnsubBackdrop, setIsShowUnsubBackdrop] = useState(false)

  const [email, setEmail] = useState("")
  const [emailErrMsg, setEmailErrMsg] = useState<string>("")

  const {
    id,
    intervalCount,
    interval,
    currency,
    startUnixS,
    endUnixS,
    product,
    price,
    status,
    cancelAt,
    isTransferrable,
    hasPrevTransferPassMinDuration,
    transferDetails,
  } = sub

  const handleUnsubscribeBtnClick = () => {
    setIsShowRatingBackdrop(true)
  }

  const handleYesClick = async () => {
    setIsShowTransferPromptBackdrop(false)
    if (transferDetails) {
      setIsShowExistingTransferBackdrop(true)
    } else {
      setIsShowTransferBackdrop(true)
    }
  }
  const handleNoClick = async () => {
    setIsShowTransferPromptBackdrop(false)
    setIsShowUnsubBackdrop(true)
  }

  const handleSubmitRating = async (rating: number) => {
    if (!rating) {
      alert("Please select a rating")
      return
    }

    await upsertUnsubSurveyResponse({
      rating,
    })
    setIsShowRatingBackdrop(false)
    if (isTransferrable) {
      setIsShowTransferPromptBackdrop(true)
    } else {
      setIsShowUnsubBackdrop(true)
    }
  }

  const upsertUnsubSurveyResponse = async ({
    rating,
    surveyResponse,
    mobileNo,
  }: {
    rating?: number
    surveyResponse?: string
    mobileNo?: string
  }) => {
    const url = "/unsub-survey-responses"
    try {
      const { data } = await axiosPrivate.post(url, {
        id: surveyResponseId,
        ...(rating && { rating }),
        ...(surveyResponse && {
          questions: [
            {
              text: "Have you passed your RES Exam?",
              answer: surveyResponse,
            },
          ],
        }),
        ...(mobileNo && { mobileNo }),
      })

      if (data?.data) {
        setSurveyResponseId(data.data)
      }
    } catch (err) {
      alert("Something went wrong. Try again later.")
    }
  }

  // const handleBackToHomeClick = async () => {
  //   if (jumpstartCareerResponse === "yes") {
  //     const errMsg = validatePhoneNo(mobileNo)
  //     if (errMsg) {
  //       setInvalidPhoneNoErrMsg(errMsg)
  //       return
  //     }
  //     await upsertUnsubSurveyResponse({ mobileNo })
  //   }

  //   setRating(0)
  //   setSurveyResponse("")
  //   setJumpstartCareerResponse("")
  //   setMobileNo("")
  //   setInvalidPhoneNoErrMsg("")

  //   setIsShowJumpstartBackdrop(false)
  // }

  const remainingDurationDays = moment.unix(endUnixS).diff(moment(), "days")
  const remainingDurationHours = moment
    .unix(endUnixS)
    .diff(moment().add(remainingDurationDays, "days"), "hours")

  return (
    <Card sx={{ p: 2 }} variant="elevation" elevation={2}>
      {isShowRatingBackdrop && (
        <RatingBackdrop
          isShowRatingBackdrop={isShowRatingBackdrop}
          setIsShowRatingBackdrop={setIsShowRatingBackdrop}
          rating={rating}
          setRating={setRating}
          handleSubmitRating={handleSubmitRating}
        />
      )}
      {isShowTransferPromptBackdrop && (
        <PreUnsubBackdrop
          isShowBackdrop={isShowTransferPromptBackdrop}
          handleYesClick={handleYesClick}
          handleNoClick={handleNoClick}
        />
      )}
      {isShowExistingTransferBackdrop && (
        <ExistingTransferBackdrop
          transferDetails={transferDetails}
          isShowExistingTransferBackdrop={isShowExistingTransferBackdrop}
          setIsShowExistingTransferBackdrop={setIsShowExistingTransferBackdrop}
          hasPrevTransferPassMinDuration={hasPrevTransferPassMinDuration}
          setIsShowTransferBackdrop={setIsShowTransferBackdrop}
        />
      )}

      {isShowTransferBackdrop && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            overflowY: "scroll",
          }}
          open={isShowTransferBackdrop}
        >
          <Paper
            sx={{
              maxWidth: "50rem",
              minWidth: "2rem",
              backgroundColor: "white",
              borderRadius: "20px",
              mt: 5,
            }}
          >
            <Container
              sx={{
                py: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                rowGap: 2,
              }}
            >
              <Typography fontWeight="bold" variant="h6">
                Transfer Subscription
              </Typography>
              <Typography>
                Transfer the remaining
                <Typography
                  display="inline"
                  component="span"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  {` ${remainingDurationDays} days and ${remainingDurationHours} hours `}
                </Typography>
                of your subscription to another party.
              </Typography>
              <Typography fontWeight="bold" variant="h6">
                Fees
              </Typography>
              <Typography variant="body2" component="span" fontSize="1.25rem">
                You pay: $0
              </Typography>
              <Typography variant="body2" component="span" fontSize="1.25rem">
                New owner pays: $1 one-time administrative fee
              </Typography>
              <Typography variant="subtitle2">
                All other fees/considerations (if any) should be agreed upon and
                settled between you and the new owner.
              </Typography>
              <Typography fontWeight="bold" variant="h6" sx={{ rowGap: 0 }}>
                Transfer to:
              </Typography>
              <TextField
                type="email"
                helperText={emailErrMsg}
                error={emailErrMsg ? true : false}
                variant="outlined"
                label="Recipient Email"
                value={email}
                sx={{ backgroundColor: "white", width: "250px" }}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="false"
              />
              <Typography fontWeight="bold" variant="h6">
                Important
              </Typography>
              <Typography variant="subtitle2">
                * Please confirm the email address entered is correct.
                <br />* The new owner will be notified via the email and must
                accept the transfer for it to be valid. You will then lose
                access to your account.
                <br />* If the owner does not accept, you can transfer your
                subscription to another party 24 hours later.
              </Typography>
              <Box display="flex" justifyContent="space-around">
                <TransferCfmBackdrop
                  isShowBackdrop={isShowTransferCfmBackdrop}
                  setIsShowBackdrop={setIsShowTransferCfmBackdrop}
                  setIsShowTransferBackdrop={setIsShowTransferBackdrop}
                  setEmail={setEmail}
                  email={email}
                  subId={sub.id}
                />
                <Button
                  variant="contained"
                  onClick={() => {
                    setEmailErrMsg("")
                    if (!validateEmail(email)) {
                      setEmailErrMsg("Please enter a valid email.")
                      return
                    }
                    setIsShowTransferCfmBackdrop(true)
                  }}
                  role="button"
                  sx={{
                    px: 5,
                    borderRadius: 5,
                    width: "15rem",
                  }}
                >
                  Proceed
                </Button>
                <Button
                  variant="text"
                  onClick={() => setIsShowTransferBackdrop(false)}
                  color="error"
                  sx={{ width: "15rem" }}
                >
                  Cancel
                </Button>
              </Box>
            </Container>
          </Paper>
        </Backdrop>
      )}

      {isShowUnsubBackdrop && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            overflowY: "scroll",
            pt: 30,
          }}
          open={isShowUnsubBackdrop}
        >
          <Paper
            sx={{
              width: "90%",
              backgroundColor: "white",
              borderRadius: "20px",
            }}
          >
            <Container
              sx={{
                p: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                rowGap: 2,
              }}
            >
              <Typography fontWeight="bold" variant="h6">
                Cancel Subscription
              </Typography>
              <Typography>
                Are you sure you wish to unsubscribe? You will lose full access
                and progress of all your RES Exam practice questions.
              </Typography>
              <Button
                onClick={() => setIsShowUnsubBackdrop(false)}
                variant="contained"
                sx={{
                  px: 5,
                  borderRadius: "15px",
                  color: "white",
                }}
              >
                Let me reconsider
              </Button>
              <Button
                variant="text"
                onClick={() => handleUnsubscribe(id)}
                color="error"
              >
                Yes, unsubscribe
              </Button>
            </Container>
          </Paper>
        </Backdrop>
      )}

      <CardContent>
        <Typography variant="h5" gutterBottom>
          {product?.name}{" "}
          <Chip
            label={
              subStatusColorMapping[status]
                ? subStatusColorMapping[status].label
                : "Unknown"
            }
            color={
              subStatusColorMapping[status]
                ? subStatusColorMapping[status].color
                : "default"
            }
            size="small"
            sx={{ marginRight: 1 }}
          />
          {cancelAt && (
            <Chip
              label={`Cancels ${moment.unix(cancelAt).format("MMM DD")}`}
              color="default"
              size="small"
              sx={{ marginRight: 1 }}
              icon={<AccessTimeIcon />}
            />
          )}
        </Typography>
        <Typography gutterBottom>
          <strong>Price:</strong> {(price / 100).toFixed(2)}{" "}
          {currency.toUpperCase()}
        </Typography>
        <Typography gutterBottom>
          <strong>Plan Duration:</strong> {intervalCount} {interval}
        </Typography>
        <Typography gutterBottom>
          <strong>Start Date:</strong>{" "}
          {moment.unix(startUnixS).format("Do MMMM YYYY")}
        </Typography>
        <Typography gutterBottom>
          <strong>Next billing:</strong>{" "}
          {moment.unix(endUnixS).format("Do MMMM YYYY")}
        </Typography>
        {transferDetails && (
          <Box sx={{ my: 3 }}>
            <Typography variant="h6" gutterBottom>
              <u>Transfer Details</u>
            </Typography>
            <Typography gutterBottom>
              <strong>Recipient:</strong> {transferDetails?.transfereeEmail}
            </Typography>
            <Typography gutterBottom>
              <strong>Status:</strong> {transferDetails?.status}
            </Typography>
            {transferDetails?.transferredTime && (
              <Typography gutterBottom>
                <strong>Transferred Date:</strong>{" "}
                {transferDetails?.transferredTime &&
                  moment
                    .unix(transferDetails.transferredTime)
                    .format("Do MMM YYYY")}
              </Typography>
            )}
          </Box>
        )}
      </CardContent>
      <CardActions>
        {status === RsgSubStatus.active && (
          <Button
            size="small"
            color="primary"
            onClick={handleUnsubscribeBtnClick}
          >
            Unsubscribe
          </Button>
        )}
        {status === RsgSubStatus.unsubscribed && (
          <Button
            size="small"
            color="primary"
            onClick={() => handleResubscribe(id)}
          >
            Resume Subscription
          </Button>
        )}
        {status === RsgSubStatus.active && (
          <Button
            size="small"
            color="primary"
            onClick={() => {
              if (transferDetails) {
                setIsShowExistingTransferBackdrop(true)
              } else {
                setIsShowTransferBackdrop(true)
              }
            }}
            sx={{
              ...(hasPrevTransferPassMinDuration === false && {
                color: "rgba(0, 0, 0, 0.26);",
              }),
            }}
          >
            Transfer Subscription
          </Button>
        )}
      </CardActions>
    </Card>
  )
}
