import { useEffect, useState } from "react"
import { Box } from "@mui/material"
import moment from "moment"

import { MainNav } from "../components/Layout/MainNav"
import { Quizzes } from "../components/Quiz/Quizzes"
import { useAxiosPrivate } from "../hooks/useAxiosPrivate"

import { RsgSubStatus, Subscription } from "./customer/CustomerSubscription"

export const Home = () => {
  const [subTerminatingDate, setSubTerminatingDate] = useState<string | null>(
    null
  )
  const [terminatingSubId, setTerminatingSubId] = useState<string | null>(null)
  const [hasValidSub, setHasValidSub] = useState<boolean | null>(null)
  const axiosPrivate = useAxiosPrivate()

  useEffect(() => {
    getUserSubscriptions()
  }, [])

  const getUserSubscriptions = async () => {
    // require auth
    try {
      const url = "/users/subscriptions"
      const { data } = await axiosPrivate.get<{
        subscriptions: Subscription[]
      }>(url)

      if (Array.isArray(data?.subscriptions)) {
        const subs = data.subscriptions
        const hasValidSub = subs.some(
          (sub) =>
            sub.status === RsgSubStatus.active ||
            sub.status === RsgSubStatus.unsubscribed
        )
        setHasValidSub(hasValidSub)

        // If there is at least one sub with 'unsubscribed' status, find the terminating sub
        if (subs.some(({ status }) => status === RsgSubStatus.unsubscribed)) {
          const unsubscribedSubs = subs.filter(
            (sub) => sub.status === RsgSubStatus.unsubscribed
          )

          // sort by earliest cancelAt
          unsubscribedSubs.sort((a, b) => a.cancelAt - b.cancelAt)

          if (unsubscribedSubs.length > 0) {
            // get first sub that has earliest cancelAt
            const earliestTerminatingSub = unsubscribedSubs[0]

            const readabledate = moment
              .unix(earliestTerminatingSub.cancelAt)
              .format("Do MMMM YYYY")

            setSubTerminatingDate(readabledate)
            setTerminatingSubId(earliestTerminatingSub.id)
          }
        }
      }
    } catch (err: any) {
      // console.error(err.message)
    }
  }

  const handleResubscribe = async () => {
    const url = "/users/resubscribe"
    try {
      await axiosPrivate.post(url, { subId: terminatingSubId })
      await getUserSubscriptions()

      setSubTerminatingDate(null)
      setTerminatingSubId(null)
    } catch (err: any) {
      // console.log(err.message)
    }
  }

  return (
    <Box>
      <MainNav
        subTerminatingDate={subTerminatingDate}
        handleResubscribe={handleResubscribe}
      />
      <Quizzes hasValidSub={hasValidSub} />
    </Box>
  )
}
