import { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { Box, Button, Container, Typography } from "@mui/material"
import moment from "moment"
import Papa from "papaparse"

import { MainNav } from "../components/Layout/MainNav"
import { SurveyResponsesTable } from "../components/SurveyResponse/SurveyResponsesTable"
import { useAxiosPrivate } from "../hooks/useAxiosPrivate"

export interface SurveyQuestion {
  text: string
  answer: string
}
export interface ISurveyResponse {
  _id: string
  createdAt: string
  email: string
  mobileNo: string
  rating: number
  questions: SurveyQuestion[]
}

export interface IParsedSurveyResponse {
  createdAt: string
  email: string
  mobileNo: string
  rating: number
  question1: string
  question1Answer: string
}

export const SurveyResponse = () => {
  const axiosPrivate = useAxiosPrivate()
  const [surveyResponses, setSurveyResponses] = useState<
    IParsedSurveyResponse[]
  >([])
  const [startDate, setStartDate] = useState<Date | null>(
    moment().subtract(1, "months").toDate()
  )
  const [endDate, setEndDate] = useState<Date | null>(new Date())

  const handleDownloadCSV = async () => {
    const startUnixMs = moment(startDate).valueOf()
    const endUnixMs = moment(endDate).valueOf()
    const { data } = await axiosPrivate.get<{
      surveyResponses: ISurveyResponse[]
    }>(
      `/unsub-survey-responses?startUnixMs=${startUnixMs}&endUnixMs=${endUnixMs}`
    )
    const parsedSurveyResponses = parseSurveyResponses(data.surveyResponses)
    const csv = Papa.unparse(parsedSurveyResponses)
    const blob = new Blob([csv], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")
    const startDateFormatted = moment(startDate).format("YYMMDD")
    const endDateFormatted = moment(endDate).format("YYMMDD")
    link.download = `${startDateFormatted}-${endDateFormatted}-survey-responses.csv`
    link.href = url
    link.click()
  }

  const parseSurveyResponses = (surveyResponses: ISurveyResponse[]) => {
    return surveyResponses.map(
      ({ createdAt, email, mobileNo, rating, questions }) => {
        return {
          createdAt: moment(createdAt).format("YYYY-MM-DD HH:mm"),
          email,
          rating,
          mobileNo,
          question1: questions[0]?.text,
          question1Answer: questions[0]?.answer,
        }
      }
    )
  }

  useEffect(() => {
    const getSurveyResponses = async () => {
      try {
        const url = "/unsub-survey-responses"
        const { data } = await axiosPrivate.get(url)

        if (Array.isArray(data?.surveyResponses)) {
          const parsedSurveyResponses = parseSurveyResponses(
            data.surveyResponses
          )
          setSurveyResponses(parsedSurveyResponses)
        }
      } catch (err: any) {
        console.error(err.message)
      }
    }

    getSurveyResponses()
  }, [])

  return (
    <Box>
      <MainNav />
      <Container sx={{ mt: 5 }}>
        <Typography variant="h4" sx={{ color: "rgb(42, 124, 111)", mb: 2 }}>
          Survey Response
        </Typography>
        <Box display="flex" sx={{ my: 5 }} alignItems="center" columnGap={3}>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="yyyy-MM-dd"
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="yyyy-MM-dd"
          />
          <Button
            variant="contained"
            size="small"
            onClick={async () => await handleDownloadCSV()}
          >
            Export CSV
          </Button>
        </Box>
        <SurveyResponsesTable surveyResponses={surveyResponses} />
      </Container>
    </Box>
  )
}
