import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Paper } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { Api } from '../Api'
import { validateComment } from '../helpers/inputValidators'
import { useAxiosPrivate } from '../hooks/useAxiosPrivate'

export const CommentBackdrop = ({ quizId, quizTopic, quizQuestionNo, quizCategory, quizQuestion, isShowCommentBackdrop, setIsShowCommentBackdrop }: { quizId: string | undefined; quizTopic: string; quizQuestionNo: number | null; quizCategory: string; quizQuestion: string | null; isShowCommentBackdrop: boolean; setIsShowCommentBackdrop: any }) => {
  const axiosPrivate = useAxiosPrivate()
  const commentRef = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()
  /**
   * Comment
   */
  const [comment, setComment] = useState('')
  const [isSubmittingComment, setIsSubmittingComment] = useState(false)
  const [invalidCommentText, setInvalidCommentText] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)

  useEffect(() => {
    commentRef.current?.focus()
  }, [])

  const handleSubmitComment = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setIsSubmittingComment(true)
    setInvalidCommentText('')

    // validate comment
    const errMsg = validateComment(comment)

    if (errMsg) {
      setInvalidCommentText(errMsg)
      setIsSubmittingComment(false)
      return
    }

    const quizComment = {
      quizId: quizId,
      quizQuestionNo: quizQuestionNo,
      quizCategory: quizCategory,
      quizTopic: quizTopic,
      quizQuestion: quizQuestion,
      comment: comment
    }

    if (quizCategory === 'Free') {
      // don't require auth
      try {
        await Api.insertQuizComment(quizComment)
        setSuccessMessage('Thank you for your feedback.')
      } catch (err: any) {
        setInvalidCommentText('Something went wrong. Please try again later.')
      }
    } else {
      // require auth
      try {
        const url = '/quiz-comments'
        await axiosPrivate.post(url, quizComment)
        setSuccessMessage('Thank you for your feedback.')
      } catch (err) {
        navigate('/login')
      }
    }
  }

  return (
    <Backdrop
      transitionDuration={500}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 2,
        overflowY: 'scroll'
      }}
      open={isShowCommentBackdrop}
    >
      <Paper
        sx={{
          width: '90%',
          backgroundColor: 'white',
          borderRadius: '20px'
        }}
      >
        <Container
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch'
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle2" sx={{ color: 'grey' }}>
              Feedback &gt;
            </Typography>
            <CloseIcon sx={{ cursor: 'pointer' }} fontSize="medium" onClick={() => setIsShowCommentBackdrop(false)} />
          </Box>
          <Box component="form" onSubmit={handleSubmitComment} display="flex" flexDirection="column">
            <TextField
              multiline
              rows={12}
              error={invalidCommentText ? true : false}
              placeholder="Have a feedback or query? Let us know."
              variant="outlined"
              value={comment}
              sx={{ backgroundColor: '#F2F3F5', mt: 2 }}
              disabled={isSubmittingComment}
              onChange={(e) => {
                // Set error to blank
                setInvalidCommentText(null)

                setComment(e.target.value)
              }}
              inputRef={commentRef}
              autoComplete="false"
              fullWidth={true}
            />
            <Typography variant="subtitle2" color="red">
              {invalidCommentText}
            </Typography>
            <Typography variant="subtitle2" color="green">
              {successMessage}
            </Typography>
            <Button variant="contained" sx={{ px: 5, borderRadius: 5, mt: 3 }} disabled={isSubmittingComment} type="submit">
              Submit
            </Button>
          </Box>
        </Container>
      </Paper>
    </Backdrop>
  )
}
