import { useEffect, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Link } from 'react-router-dom'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'
import CloseIcon from '@mui/icons-material/Close'
import DevicesIcon from '@mui/icons-material/Devices'
import { Backdrop, Box, Button, Container, Grid, Typography } from '@mui/material'
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import { v4 as uuid } from 'uuid'

import { Api, IQuestion } from '../../Api'
import { PRIMARY_COLOR, RESEXAMSG_PRICING, STRIPE_CHECKOUT_LINK } from '../../constants'
import { useAuth } from '../../hooks/useAuth'

ChartJS.register(ArcElement, Tooltip, Legend)

const calculateScore = (questions: IQuestion[], userAnswers: string[]) => {
  let score = 0

  questions.forEach(({ type, correctChoiceIndex, possibleAnswers }, i) => {
    if (type === 'mcq') {
      if (typeof correctChoiceIndex !== 'undefined' && userAnswers[i] === correctChoiceIndex.toString()) {
        score++
      }
    } else if (type === 'fill') {
      if (typeof possibleAnswers !== 'undefined' && possibleAnswers.map((a) => a.toLowerCase()).includes(userAnswers[i].toLowerCase())) {
        score++
      }
    }
  })

  return score
}

const Paragraph = ({ body, icon }: { body: string; icon: React.ReactElement }) => {
  return (
    <Grid container>
      <Grid item xs={2} textAlign="center">
        {icon}
      </Grid>
      <Grid item xs={10}>
        <Typography variant="body1">{body}</Typography>
      </Grid>
    </Grid>
  )
}

export const QuizSummaryBackdrop = ({ userAnswers, questions, handleResetQuiz }: { userAnswers: any[]; questions: any[]; handleResetQuiz: any }) => {
  const [isBackdropOpen, setIsBackdropOpen] = useState(true)
  const [isTryAgainBdOpen, setIsTryAgainBdOpen] = useState(false)
  const { auth } = useAuth()

  const handleClickTryAgain = () => {
    setIsTryAgainBdOpen(true)
  }

  useEffect(() => {
    const email = sessionStorage.getItem('email')
    if (email) {
      // calculate correct and incorrect count
      const correctCount = calculateScore(questions, userAnswers)
      const correctPerc = Math.floor((correctCount / questions.length) * 100)
      const updateLead = async () => {
        try {
          await Api.updateLead(email, { score: correctPerc })
        } catch (err) {
          console.error(err)
        }
      }
      updateLead()
    }
  }, [])
  // calculate correct and incorrect count
  const correctCount = calculateScore(questions, userAnswers)
  const incorrectCount = questions.length - correctCount
  const correctPerc = Math.floor((correctCount / questions.length) * 100)

  const data = {
    datasets: [
      {
        label: 'My First Dataset',
        data: [100 - correctPerc, correctPerc],
        backgroundColor: ['#FF0000', '#008000'],
        hoverOffset: 4
      }
    ]
  }

  const summaryMsg = correctPerc >= 60 ? 'Well Done!' : 'Keep Practicing!'

  const points = [
    {
      body: 'Get unlimited access to over 1000+ questions based on RES course syllabus',
      icon: <AllInclusiveIcon />
    },
    {
      body: 'Practice on any device with instant feedback!',
      icon: <DevicesIcon />
    }
  ]

  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          overflowY: 'scroll',
          pt: 5
        }}
        open={isBackdropOpen}
      >
        <Container
          sx={{
            py: 3,
            backgroundColor: '#ffffff',
            color: 'black'
          }}
          maxWidth="sm"
        >
          <Box display="flex" justifyContent="flex-end">
            <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => setIsBackdropOpen(false)} />
          </Box>
          <Box textAlign="center">
            <Typography variant="h5">{summaryMsg}</Typography>
          </Box>
          <Box
            sx={{
              margin: 'auto',
              height: '200px',
              width: '200px',
              mt: 2
            }}
          >
            <Doughnut data={data} options={{ cutout: '75%' }} />
          </Box>
          <Box>
            <Typography variant="h5" textAlign="center" sx={{ mt: 2 }}>
              Your Score: {correctPerc + '%'}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-around"
            sx={{
              backgroundColor: PRIMARY_COLOR,
              py: 2,
              borderRadius: 3,
              mt: 2,
              color: 'white',
              textAlign: 'center'
            }}
          >
            <Box>
              <Typography>
                <b>Questions</b>
              </Typography>
              <Typography>{questions.length}</Typography>
            </Box>
            <Box>
              <Typography>
                <b>Correct</b>
              </Typography>
              <Typography>{correctCount}</Typography>
            </Box>
            <Box>
              <Typography>
                <b>Incorrect</b>
              </Typography>
              <Typography>{incorrectCount}</Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" sx={{ mt: 2 }} gap={2}>
            <a href={RESEXAMSG_PRICING} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 3,
                  backgroundColor: '#008aec',
                  color: 'white'
                }}
                fullWidth
              >
                Get RES Exam Pack
              </Button>
            </a>
            <Link style={{ textDecoration: 'none' }} to="/">
              <Button
                variant="outlined"
                sx={{
                  borderRadius: 3
                }}
                fullWidth
              >
                Back to Home
              </Button>
            </Link>
          </Box>
          {auth.accessToken ? (
            <Box display="flex" justifyContent="center" gap={2} mt={3}>
              <Button variant="outlined" sx={{ borderRadius: 4, px: 5 }} onClick={() => handleClickTryAgain()}>
                Try again
              </Button>
              <Link to="/" style={{ textDecoration: 'none' }}>
                <Button variant="contained" sx={{ backgroundColor: '#151d33', borderRadius: 4, px: 5 }}>
                  Back to main
                </Button>
              </Link>
            </Box>
          ) : (
            <Box>
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  border: 'black 1px solid',
                  borderRadius: 3,
                  py: 3,
                  px: 1,
                  mt: 2,
                  color: 'black'
                }}
                gap={3}
              >
                {points.map(({ body, icon }) => {
                  return <Paragraph key={uuid()} body={body} icon={icon} />
                })}
              </Box>
            </Box>
          )}
        </Container>
      </Backdrop>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={isTryAgainBdOpen}
      >
        <Container
          sx={{
            py: 3,
            backgroundColor: '#ffffff',
            color: 'black',
            textAlign: 'center',
            borderRadius: 5
          }}
          maxWidth="xs"
        >
          <Typography variant="body1">Your score will be reset.</Typography>
          <Typography variant="body1">Proceed to try again?</Typography>
          <Box display="flex" justifyContent="center" gap={2} mt={3}>
            <Button variant="outlined" sx={{ borderRadius: 4, px: 5 }} onClick={() => setIsTryAgainBdOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'black', borderRadius: 4, px: 5 }}
              onClick={() => {
                handleResetQuiz()
                setIsTryAgainBdOpen(false)
                setIsBackdropOpen(false)
              }}
            >
              Yes
            </Button>
          </Box>
        </Container>
      </Backdrop>
    </>
  )
}
