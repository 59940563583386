import { useNavigate } from 'react-router-dom'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import { Box, Grid, Paper, Typography } from '@mui/material'

import { IQuiz, QuizCategory } from '../../Api'
import { useAuth } from '../../hooks/useAuth'
import { ProgressBar } from '../ProgressBar'

export const QuizCard = ({
  quiz: {
    _id,
    topic,
    category,
    imageLink,
    noOfQuestions,
    questionsCompleted,
    score
  },
  handleResetQuiz
}: {
  quiz: IQuiz
  handleResetQuiz: any
}) => {
  const navigate = useNavigate()
  const { auth } = useAuth()
  const progressPerc = Math.floor((questionsCompleted / noOfQuestions) * 100)
  let scorePerc = null
  if (score !== null && score !== undefined) {
    scorePerc = Math.floor((score / noOfQuestions) * 100)
  }
  const isCardAccessible =
    (!auth.accessToken && category === QuizCategory.FREE) || auth.accessToken

  const handleClickQuiz = (id: string) => () => {
    if (isCardAccessible) {
      navigate(`/quizzes/${id}`)
    }
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Paper
        variant="elevation"
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          ...(!isCardAccessible && { opacity: 0.3 }),
          ...(isCardAccessible && { cursor: 'pointer' })
        }}
        onClick={handleClickQuiz(_id)}
      >
        <Box
          component="img"
          src={imageLink || 'https://picsum.photos/id/1/500/500'}
          alt={_id}
          sx={{
            objectFit: 'cover',
            width: '100%',
            height: '150px'
          }}
        />
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box>
              <Typography variant="h6" component="h2">
                {topic}
              </Typography>
            </Box>
            <Box>
              <RestartAltIcon
                sx={{
                  fontSize: 25,
                  ...(isCardAccessible && { cursor: 'pointer' })
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  if (isCardAccessible) {
                    handleResetQuiz(_id)
                  }
                }}
              />
            </Box>
          </Box>
          <Box>
            <Typography
              variant="subtitle2"
              component="p"
              sx={{ color: '#000000' }}
            >
              {noOfQuestions} {noOfQuestions > 1 ? 'Questions' : 'Question'}
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 3,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Box sx={{ flex: 3, mr: 1 }}>
              <ProgressBar progress={progressPerc} />
            </Box>
            <Box sx={{ flex: 1, textAlign: 'center' }}>{progressPerc}%</Box>
          </Box>
          {scorePerc !== null && <Box>{`Score: ${scorePerc}%`}</Box>}
        </Box>
      </Paper>
    </Grid>
  )
}
