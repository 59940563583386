import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material"
import { v4 as uuid } from "uuid"

import { IQuiz, QuizCategory } from "../../Api"
import { PRIMARY_COLOR, PRIMARY_FONT, ROLES } from "../../constants"
import axios from "../../helpers/axios"
import { useAuth } from "../../hooks/useAuth"
import { useAxiosPrivate } from "../../hooks/useAxiosPrivate"

import { QuizCard } from "./QuizCard"

export const Quizzes = ({ hasValidSub }: { hasValidSub: boolean | null }) => {
  const [quizzes, setQuizzes] = useState<IQuiz[]>([])
  const { auth } = useAuth()
  const [isLoadingQuizzes, setIsLoadingQuizzes] = useState(true)
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const getUserQuizzes = async () => {
      setIsLoadingQuizzes(true)
      try {
        const {
          data: { quizzes },
        } = await axiosPrivate("/user-quizzes")
        setQuizzes(quizzes)
      } catch (err) {
        console.error(err)
        navigate("/login", { state: { from: location }, replace: true })
      } finally {
        setIsLoadingQuizzes(false)
      }
    }

    const getQuizzes = async () => {
      setIsLoadingQuizzes(true)
      try {
        const {
          data: { quizzes },
        } = await axios("/quizzes")
        setQuizzes(quizzes)
      } catch (err) {
        console.error(err)
        navigate("/login", { state: { from: location }, replace: true })
      } finally {
        setIsLoadingQuizzes(false)
      }
    }

    if (auth.accessToken) {
      getUserQuizzes()
    } else {
      getQuizzes()
    }
  }, [])

  const handleResetQuiz = async (id: string) => {
    // ask for confirmation
    const confirm = window.confirm("Are you sure?")
    if (confirm) {
      if (auth.accessToken) {
        try {
          const url = `/user-quizzes/${id}`
          await axiosPrivate.patch(url, { answers: [] })
        } catch (err) {
          console.error("Failed to reset user quiz.")
          return
        }
      } else {
        // reset local storage
        sessionStorage.setItem(id, JSON.stringify([]))
      }

      // if update is successful, update frontend quiz
      const updatedQuizzes = quizzes.map((quiz) => {
        if (quiz._id === id) {
          return { ...quiz, questionsCompleted: 0, score: null }
        }
        return quiz
      })

      setQuizzes(updatedQuizzes)
    }
  }

  const categories: QuizCategory[] = [
    QuizCategory.PAPER_1,
    QuizCategory.PAPER_2,
  ]

  if (!auth.accessToken) {
    categories.unshift(QuizCategory.FREE)
  }

  const isAdmin = auth?.accessToken && auth?.roles?.includes(ROLES.Admin)
  const isLogin = auth?.accessToken

  return (
    <Container sx={{ mb: 10 }}>
      {isLogin && hasValidSub === false && !isAdmin && (
        <Box
          sx={{
            textAlign: "center",
            mt: 8,
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontFamily: PRIMARY_FONT, fontWeight: 500 }}
          >
            Oops… Your subscription has ended
          </Typography>
          <Box
            component="img"
            src="/404.png"
            alt="404.png"
            sx={{ maxWidth: "100%", height: "auto" }}
          />
          <Typography variant="subtitle1" sx={{ fontFamily: PRIMARY_FONT }}>
            Please subscribe again at resexam.sg via the same email.
          </Typography>
          <Typography variant="subtitle1" sx={{ fontFamily: PRIMARY_FONT }}>
            You can still use your referral link to refer friends and earn up to
            $8.90 per referral with no limits!
          </Typography>
          <Box mt={4} display="flex" justifyContent="center" gap={4}>
            <a href="https://resexam.sg" target="_blank" rel="noreferrer">
              <Button
                variant="contained"
                sx={{ backgroundColor: "#2a7c6f", px: 5 }}
              >
                Sign Up
              </Button>
            </a>
            <a href="/profile/referrals" rel="noreferrer">
              <Button
                variant="contained"
                sx={{ backgroundColor: "#2a7c6f", px: 5 }}
              >
                Refer & Earn
              </Button>
            </a>
          </Box>
        </Box>
      )}

      {categories.map((category) => {
        const quizzesByCategory = quizzes.filter(
          (quiz) => quiz.category === category
        )

        return (
          <Box key={uuid()} sx={{ mt: 2 }}>
            <Box sx={{ backgroundColor: PRIMARY_COLOR, color: "white" }}>
              <Typography variant="h5" sx={{ mb: 2, p: 1, pl: 2 }}>
                {category}
              </Typography>
            </Box>
            {isLoadingQuizzes && (
              <Box py={5} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}
            {!isLoadingQuizzes && (
              <Grid container sx={{ mt: 5 }} spacing={{ xs: 2 }}>
                {quizzesByCategory.map((quiz) => {
                  return (
                    <QuizCard
                      key={quiz._id}
                      quiz={quiz}
                      handleResetQuiz={handleResetQuiz}
                    />
                  )
                })}
              </Grid>
            )}
          </Box>
        )
      })}
    </Container>
  )
}
