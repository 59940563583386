import { Box, Button, Grid, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'

import { GREEN_COLOR, PLAN_CATEGORY } from '../constants'

const DESCRIPTION_STYLE = {
  color: GREEN_COLOR,
  letterSpacing: '2.4px',
  fontFamily: 'sans-serif'
}

export const ExamPack = ({ category, displayName, interval, intervalCount, paymentLink, savings, unitAmount, unitAmountPromo }: { category: PLAN_CATEGORY; displayName: string; interval: string; intervalCount: number; paymentLink: string; savings: string; unitAmount: number; unitAmountPromo: number }) => {
  // Append 0 to months if less than 10
  const noOfMonthsStr = intervalCount < 10 ? `0${intervalCount}` : `${intervalCount}`
  // Convert price from cents to price
  const priceDollars = (unitAmount / 100).toFixed(2)

  const priceDollarsPromo = unitAmountPromo ? (unitAmountPromo / 100).toFixed(2) : null

  return (
    <Grid xs={12} md={4} item display="flex" flexDirection="column" alignItems="center" gap={3}>
      <Avatar
        alt="Remy Sharp"
        sx={{
          width: 96,
          height: 96,
          backgroundImage: `url(${'/month_avatar.jpg'})`
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Typography sx={{ fontWeight: 'bold' }} variant="h6">
            {noOfMonthsStr}
          </Typography>
          <Typography>Month</Typography>
        </Box>
      </Avatar>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography letterSpacing="2.7px" fontWeight="bold" color={GREEN_COLOR} fontSize="18px">
          {displayName}
        </Typography>
        <Typography letterSpacing="2.7px" fontWeight="bold" color={GREEN_COLOR} fontSize="18px" sx={{ ...(priceDollarsPromo && { textDecoration: 'line-through' }) }}>
          ${priceDollars}
        </Typography>
        {priceDollarsPromo && (
          <Typography letterSpacing="2.7px" fontWeight="bold" color="red" fontSize="18px">
            ${priceDollarsPromo}
          </Typography>
        )}
        {savings && (
          <Typography letterSpacing="2.7px" color={GREEN_COLOR} fontSize="18px">
            (Save {savings})
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" gap={6} mt={3}>
        {category === PLAN_CATEGORY.PAPER_1 && <Typography sx={DESCRIPTION_STYLE}>Paper 1 only</Typography>}
        {category === PLAN_CATEGORY.PAPER_2 && <Typography sx={DESCRIPTION_STYLE}>Paper 2 only</Typography>}
        <Typography sx={DESCRIPTION_STYLE}>
          {intervalCount} {interval && `${interval[0].toUpperCase()}${interval.slice(1).toLowerCase()}`} Unlimited Access
        </Typography>
        <Typography sx={DESCRIPTION_STYLE}>Clustered by topics</Typography>
        <Typography sx={DESCRIPTION_STYLE}>Available on all devices</Typography>
        <Typography sx={DESCRIPTION_STYLE}>
          Simple to understand <br /> feedback & explanation
        </Typography>
      </Box>
      <a href={paymentLink} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#3BBFAA',
            borderRadius: '200px',
            ':hover': {
              bgcolor: 'white',
              color: 'black',
              border: '1px solid black'
            },
            letterSpacing: '1.6px',
            mt: 3
          }}
        >
          GET ACCESS
        </Button>
      </a>
    </Grid>
  )
}
