import Box from "@mui/material/Box"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import moment from "moment"
import { v4 as uuid } from "uuid"

import { IParsedSurveyResponse, SurveyQuestion } from "../../pages/SurveyResponse"

export const SurveyResponsesTable = ({ surveyResponses }: { surveyResponses: IParsedSurveyResponse[] }) => {
  const columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "Date",
      valueFormatter: ({ value }) => {
        return moment(value).format("Do MMM hh:mm A")
      },
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "rating",
      headerName: "Rating",
      width: 100,
    },
    {
      field: "mobileNo",
      headerName: "Mobile No.",
      width: 150,
    },
    {
      field: "question1",
      headerName: "Question",
      type: "string",
      width: 250,
    },
    {
      field: "question1Answer",
      headerName: "Answer",
      width: 150,
    },
  ]

  return (
    <Box sx={{ height: 800, width: "100%" }}>
      <DataGrid getRowId={(row) => uuid()} rows={surveyResponses} columns={columns} pageSize={20} rowsPerPageOptions={[10, 20, 30]} />
    </Box>
  )
}
