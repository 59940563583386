import axios from '../helpers/axios'

import { useAuth } from './useAuth'

export const useLogout = () => {
  const { setAuth } = useAuth()
  const logout = async () => {
    setAuth({})
    try {
      const response = await axios('/otp/logout', {
        withCredentials: true
      })
    } catch (err) {
      console.error(err)
    }
  }

  return logout
}
