import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { Api } from '../Api'

export const Free2 = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const track = async () => {
      if (!sessionStorage.getItem('sessionId')) {
        const sessionId = uuid()
        // set sessionId in session storage
        sessionStorage.setItem('sessionId', sessionId)
        await Api.insertSession(sessionId, 'Free2')
      } else {
        await Api.insertSession(
          sessionStorage.getItem('sessionId') as string,
          'Free2'
        )
      }

      navigate(`/quizzes/${process.env.REACT_APP_FREE_QUIZ_ID_2 || ''}`)
    }
    track()
  })

  return <div>Free</div>
}
