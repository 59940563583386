import { useNavigate } from 'react-router-dom'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import SavingsIcon from '@mui/icons-material/Savings'
import { Box, Button } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import NavBar from '../../components/Layout/NavBar'
import { useLogout } from '../../hooks/useLogout'

export const Profile = () => {
  const navigate = useNavigate()
  const logout = useLogout()

  return (
    <Box>
      <NavBar title="Profile" navigateTo="/" />
      <List>
        <ListItem divider={true}>
          <ListItemButton onClick={() => navigate('/profile/referrals')}>
            <ListItemIcon>
              <SavingsIcon />
            </ListItemIcon>
            <ListItemText primary="Refer & Earn" />
            <ListItemIcon>
              <NavigateNextIcon color="primary" sx={{ mr: 1, cursor: 'pointer' }} onClick={() => navigate('/')} />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => navigate('/profile/subscriptions')}>
            <ListItemIcon>
              <CreditCardIcon />
            </ListItemIcon>
            <ListItemText primary="Manage Subscription" />
            <ListItemIcon>
              <NavigateNextIcon sx={{ mr: 1, cursor: 'pointer' }} />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      </List>
      <Box display="flex" justifyContent="center" sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="medium"
          onClick={async () => {
            await logout()
            navigate('/')
          }}
          sx={{ textTransform: 'capitalize' }}
        >
          Logout
        </Button>
      </Box>
    </Box>
  )
}
