export function validateEmail(email: string) {
  return /^.+@.+\..+$/.test(email)
}

export function validateOtp(otp: string) {
  return !isNaN(parseInt(otp))
}

export function validateComment(comment: string) {
  // should not be less than 10 characters
  if (comment.length < 10) {
    return 'Please enter a comment with more than 10 characters.'
  } else if (comment.length > 500) {
    return 'Please enter a comment that is less than 500 characters.'
  }
  return null
}

export function validatePhoneNo(input: string) {
  const numericPattern = /^[0-9]+$/

  if (!numericPattern.test(input)) {
    return 'Only numbers are allowed.'
  }

  if (input.length !== 8) {
    return 'Please enter a valid phone number.'
  }
}
